import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import { createTheme } from '@mui/material/styles';
import axios from "axios";
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import ApiConfig from '../ApiConfig';
import SocietyHeader from './SocietyHeader';


const theme = createTheme({
    spacing: 8,
});

const AddFlat = () => {
    const [blockName, setBlockName] = useState('');
    const [towerName, setTowerName] = useState('');
    const [floors, setFloors] = useState([{ floorNumber: 0, flats: 0 }]);
    const [flatNumbers, setFlatNumbers] = useState([]);
    const [isGenerated, setIsGenerated] = useState(false);
    const isLogged = Boolean(localStorage.getItem("isLoggedIn"))


    const handleAddFloor = () => {
        setFloors([...floors, { floorNumber: floors.length, flats: 0 }]);
    };

    const handleFloorChange = (index, flats) => {
        const newFloors = [...floors];
        newFloors[index].flats = parseInt(flats);
        setFloors(newFloors);
    };

    const generateFlatNumbers = () => {
        let newFlatNumbers = [];
        floors.forEach((floor, index) => {
            for (let i = 1; i <= floor.flats; i++) {
                let prefix = index === 0 ? 'G' : index.toString();
                let number = i.toString().padStart(2, '0');
                newFlatNumbers.push(`${prefix}${number}`);
            }
        });
        setFlatNumbers(newFlatNumbers);
        setIsGenerated(true);
    };

    const handleFlatNumberChange = (index, newValue) => {
        const newFlatNumbers = [...flatNumbers];
        newFlatNumbers[index] = newValue;
        setFlatNumbers(newFlatNumbers);
    };

    const saveFlats = async () => {
        try {
            const response = await axios.post(`${ApiConfig.baseUrl}api/societyManagement/saveFlats`, {
                blockName,
                towerName,
                floors,
                flatNumbers,
            });

            if (response.status === 200) {
                alert('Flats saved successfully!');
            } else {
                throw new Error('Failed to save flats');
            }
        } catch (error) {
            console.error('Error saving flats:', error);
            alert('Failed to save flats. Please try again.');
        }
    };

    const handleReset = () => {
        setBlockName('');
        setTowerName('');
        setFloors([{ floorNumber: 0, flats: 0 }]);
        setFlatNumbers([]);
        setIsGenerated(false);
    };

    if (!isLogged) {
        return <Navigate to={"/"} replace={true} />
    }

    return (
        <div>
            <SocietyHeader />
            <div className="SlotMas_form">
                <div className="form_head">Society Management</div>
                <div className="form_body">
                    <div className="form_field">
                        <TextField
                            label="Block Name"
                            value={blockName}
                            onChange={(e) => setBlockName(e.target.value)}
                            style={{ width: '60%' }}
                        />
                    </div>
                    <div className="form_field">
                        <TextField
                            label="Tower Name"
                            value={towerName}
                            onChange={(e) => setTowerName(e.target.value)}
                            style={{ width: '60%' }}

                        />
                    </div>
                    {floors.map((floor, index) => (
                        <div key={index} className="form_field">
                            <TextField
                                label={`Floor ${floor.floorNumber} Flats`}
                                type="number"
                                value={floor.flats}
                                onChange={(e) => handleFloorChange(index, e.target.value)}
                                style={{ width: '60%' }}
                            />
                        </div>
                    ))}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '10px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddFloor}
                                style={{ marginRight: '10px' }}
                            >
                                Add Floor
                            </Button>
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={handleReset}
                                startIcon={<RefreshIcon />}
                                style={{ marginRight: '20px' }}

                            >
                                Reset
                            </Button>
                        </div>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={generateFlatNumbers}
                            style={{ width: '200px' }}
                        >
                            Generate Flat Numbers
                        </Button>
                    </div>
                    {isGenerated && (
                        <>
                            <h3>Flat Numbers</h3>
                            <Grid container spacing={1}>
                                <Grid item xs={11.5} style={{ paddingLeft: theme.spacing(10) }}>
                                    <TableContainer component={Paper} style={{ maxHeight: 1000, overflowY: 'auto' }}>
                                        <Table>
                                            <TableHead className="tableHeadBold">
                                                <TableRow>
                                                    <TableCell className="tb_cell">Flat Number</TableCell>
                                                    {/* <TableCell className="tb_cell">Edit</TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_data">
                                                {flatNumbers.map((flatNumber, index) => (
                                                    <TableRow key={index} className="tb-row">
                                                        <TableCell className="row_cell">
                                                            <TextField
                                                                value={flatNumber}
                                                                onChange={(e) => handleFlatNumberChange(index, e.target.value)}
                                                            />
                                                        </TableCell>
                                                        {/* <TableCell className="row_cell">
                              <IconButton onClick={() => handleFlatNumberChange(index, '')}>
                                <EditIcon className="edit-icon" />
                              </IconButton>
                            </TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <div style={{ marginLeft: '35%', marginRight: '41%', marginTop: '20px' }}>
                                <Button style={{ float: 'left' }} variant="contained" color="success" onClick={saveFlats}>
                                    Save Flats
                                </Button>
                                <Button style={{ float: 'right' }} variant="contained" color="warning" onClick={handleReset} startIcon={<RefreshIcon />}>
                                    Reset
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddFlat;