import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { createTheme } from '@mui/material/styles';
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import ApiConfig from "../ApiConfig";
import './LevelForm.css';
import PublicHeader from "./publicHeader";


const theme = createTheme({
  spacing: 8,
});


const LevelForm = () => {
  const [level, setLevel] = useState("");
  const [lanes, setLanes] = useState("");
  const [slots, setSlots] = useState([]);
  const [data, setData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [levelError, setLevelError] = useState("");
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiConfig.baseUrl}parkingslots`);
        const parsedData = response.data.map(item => ({
          ...item,
          slots: Array.isArray(item.slots) ? item.slots : JSON.parse(item.slots)
        }));
        setData(Array.isArray(parsedData) ? parsedData : [parsedData]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleReset = () => {
    setLevel("");
    setLanes("");
    setSlots([]);
    setEditingIndex(-1);
    setLevelError("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (editingIndex === -1) {
      try {
        const response = await axios.get(
          `${ApiConfig.baseUrl}levels/${level}`
        );
        if (response.data) {
          setLevelError(
            "Level already exists. Please enter a different level."
          );
          return;
        }
      } catch (error) {
        console.error(error);
      }
      try {
        const response = await axios.post(`${ApiConfig.baseUrl}save`, {
          level,
          lanes,
          slots,
        });
        setData([...data, response.data]);
        setLevelError("");
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await axios.get(
          `${ApiConfig.baseUrl}levels/${level}`
        );
       } catch (error) {
        console.error(error);
      }
      // updating the record
      try {
        await axios.put(
          `${ApiConfig.baseUrl}update/${data[editingIndex].id}`,
          { level, lanes, slots }
        );
        const updatedData = [...data];
        updatedData[editingIndex] = { level, lanes, slots };
        setData(updatedData);
        alert("Record updated sucessfully !");
        setEditingIndex(-1);
      } catch (error) {
        console.error(error);
      }
    }
    setLevel("");
    setLanes("");
    setSlots([]);
  };

  const handleSlotChange = (event, index) => {
    const updatedSlots = [...slots];
    updatedSlots[index] = event.target.value;
    setSlots(updatedSlots);
  };

  const handleDeleteSlot = (index) => {
    const updatedSlots = [...slots];
    updatedSlots.splice(index, 1);
    setSlots(updatedSlots);
  };

  const handleUpdate = (index) => {
    const { level, lanes, slots } = data[index];
    setLevel(level);
    setLanes(lanes);
    // setSlots(slots);
    setSlots(Array.isArray(slots) ? slots : JSON.parse(slots));
    setEditingIndex(index);
  };

  const handleDelete = async (index) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete level ${data[index].level}?`
    );
    if (confirmDelete) {
      try {
        const id = data[index].id;
        await axios.delete(`${ApiConfig.baseUrl}delete/${id}`);
        const updatedData = [...data];
        updatedData.splice(index, 1);
        setData(updatedData);
        alert("Record deleted successfully!");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleLevelBlur = async (event) => {
    const { value } = event.target;
    try {
      const response = await axios.get(`${ApiConfig.baseUrl}levels/${value}`);
      if (response.data) {
        setLevelError(
          `Level ${value} already exists. Please enter a different level.`
        );
      } else {
        setLevelError("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // calculate total number of parking slots
  const totalParkingSlots = Array.isArray(data) && data.reduce((acc, { slots }) =>
        acc + slots.reduce((sum, slot) => sum + parseInt(slot), 0), 0 );

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}
        
  return (
   <div>
    <PublicHeader />
    <form onSubmit={handleSubmit}>
        <div  className="SlotMas_form">
        <div className="form_head" > Parking Slot Master </div>
     
         <div className="form_body">
          <div className="form_field">
              <TextField label="Level" type="number" value={level} onChange={(event) => setLevel(event.target.value)}
              onBlur={handleLevelBlur} error={Boolean(levelError)}  helperText={levelError}  style={{width:'400px'}} /> 
          </div>
          <div className="form_field">
              <TextField label="Lanes" type="number" value={lanes} onChange={(event) => setLanes(event.target.value)}  style={{width:'400px'}}  />
          </div>      

                  

          {Array.from({ length: lanes }, (_, index) => (
            <div  className="form_field">
              <TextField  label={`Lane ${index + 1} Slots`} type="number"
                value={slots[index]} onChange={(event) => handleSlotChange(event, index)}  style={{width:'400px'}}  />
             
              <IconButton onClick={() => handleDeleteSlot(index)}>
                <DeleteIcon className="delete-icon" />
              </IconButton>
            </div>
          ))}

          <div style={{  marginLeft: '35%',  marginRight: '41%'}}>
            <Button style={{float:'left'}} variant="contained" color="success" onClick={handleSubmit}  >
              {editingIndex === -1 ? "Save" : "Update"}
            </Button>
            <Button style={{float:'right'}} variant="contained" color="warning" onClick={handleReset}  startIcon={<RefreshIcon />} >
              Reset
            </Button>
          </div>
          </div>
        
        </div>
      </form>
<hr/>
      <Grid container spacing={1}>
      <Grid item xs={11.5} style={{ paddingLeft: theme.spacing(10) }}>
        <TableContainer component={Paper} style={{ maxHeight: 400, overflowY: 'auto' }}>
          <Table>
            <TableHead className="tableHeadBold">
              <TableRow>
                <TableCell className="tb_cell">Level</TableCell>
                <TableCell className="tb_cell">Lanes</TableCell>
                <TableCell className="tb_cell">Slots</TableCell>
                <TableCell className="tb_cell">Total</TableCell>
                <TableCell className="tb_cell">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table_data">
              {data.map((item, index) => (
                <TableRow key={item.id} className="tb-row">
                  <TableCell className="row_cell">{item.level}</TableCell>
                  <TableCell className="row_cell">{item.lanes}</TableCell>
                  <TableCell className="row_cell">{item.slots.join(", ")}</TableCell>
                  <TableCell className="row_cell">
                    {item.slots
                      .map((slot) => Number(slot))
                      .reduce((acc, val) => acc + val, 0)}
                  </TableCell>
                  <TableCell className="row_cell">
                    <IconButton onClick={() => handleUpdate(index)}>
                      <EditIcon className="edit-icon" />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon className="delete-icon" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: theme.spacing(10) }}>
        Total Parking Slots: {totalParkingSlots}
      </Grid>
</Grid>
   </div>
  
  
  );
};

export default LevelForm;
