import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from './../../images/logo.png';
import './../../App.css'
import { Navigate } from 'react-router-dom';


const PublicHeader=()=>{
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))
  
  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}

    return (
        <div className='row' style={{backgroundColor:'rgb(1, 1, 82)',height:'75px',padding:'6px 16px',color:'white'}}>
        <div className='col-md-6'>
          <img src={logo} style={{height:'50px',width:'50px', borderRadius:'50%'}} />
          <div style={{fontWeight:'600',fontSize:'13px'}}>PARKING</div>

         
        </div>
        <div className='col-md-6 menu ' >
          <div className='float-right menu-item'><NavLink activeClassName="active" to="/attendent">Manage Attendent</NavLink></div>
          <div className='float-right menu-item'><NavLink activeClassName="active" to="/parking-settings">Parking Settings</NavLink></div>
         </div>
          
        </div>
    )
}

export default PublicHeader;