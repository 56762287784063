import axios from "axios";
import React, { useContext, useState } from "react";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Header from "../header";
import '../publicParking/LevelForm.css';
// import { AuthContext } from "../context/AuthContext";
import { AuthContext } from "../../context/AuthContext";
import ApiConfig from "../ApiConfig";

const Login = () => {

  const [errorMessages, setErrorMessages] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setAuthData } = useContext(AuthContext);

  // const [data, setData] = useState([]);
  // const data ={name:'Admin', pass:'Admin'}
  // const errors = {
  //     name: "Admin with this name does not exist.",
  //     pass: "Wrong Password."
  //   };

  const handleSubmit = async (event) => {
    event.preventDefault();

    var { userName, userPassword } = document.forms[0];
    // console.log(userName, userPassword)
    const userContact = userName.value
    try {
      // const response = await axios.post("http://localhost:5015/api/users/login",
      const response = await axios.post(`${ApiConfig.baseUrl}api/users/login`,
        { userName: userName.value, userContact: userContact, userPassword: userPassword.value });
      
        // Store the session data in context or state
        console.log("Response data:", response.data);
      setAuthData(response.data);

    localStorage.setItem('loggedInId', response.data.id);
    localStorage.setItem('societyId', response.data.society_id);
    localStorage.setItem('userType', response.data.userType);
    localStorage.setItem('parkingType', response.data.userType);
    localStorage.setItem('isLoggedIn', true);


      // console.log(response)
      if (response.data.userType == 0) {
        navigate('/admin')
      }
      else if (response.data.userType == '1') {
        if (response.data.parkingType == '1') {
          navigate('/parking-settings')
        }
        else if (response.data.parkingType == '2') {
          navigate('/upload-data')
        }
      }
      else if (response.data.userType == 2) {
        navigate('/customer-information', { state: response.data.id })
      }
      else if (response.data.userType == 3) {
        if (response.data.parkingType == '1') {
          navigate('/parking-module') //Public Parking Attendant
        }
        else if (response.data.parkingType == '2') {
          navigate('/visitor-request') // Society Parking GateKeeper
        }
      }


    } catch (error) {
      console.error(error);

    }

  };


  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );


  return (
    <div>
      <Header />
      <div className='row'>
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="SlotMas_form">
              <div className="form_head" >Login </div>
              <div className="form_body mt-3">

                <div style={{ textAlign: 'center' }}>
                  <label className="form-label" style={{ fontWeight: 'bold',  fontSize:'16px' }}>Name 
                    <input type="text" className="form-control" name="userName" required placeholder='Enter Name' />
                  </label>
                  {renderErrorMessage("name")}
                </div>
                <div className="mt-3" style={{ textAlign: 'center' }}>
                <label className="form-label" style={{ fontWeight: 'bold', fontSize:'16px' }}>Password
                    <div style={{ position: 'relative' }}>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        name="userPassword"
                        required
                        placeholder='Enter Password'
                      />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer'
                        }}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </label>
                  {renderErrorMessage("pass")}
                </div>


                <div className="mt-2" style={{ textAlign: 'center' }}>
                  <button variant="contained" className="btn btn-success" onClick={handleSubmit}  >
                    LOGIN
                  </button>

                  {/* <Link to="/parking-details">
                  <button style={{float:'right'}} variant="contained" className="btn btn-danger" >Cancel</button>
                </Link> */}

                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>

  )


};
export default Login;