import PrintIcon from '@mui/icons-material/Print';
import { IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import ApiConfig from '../ApiConfig';
import AttendentHeader from "./AttendentHeader";


const ParkingBill = () => {

    const [parkedCars, setParkedCars] = useState([]);
    const [inCarNumber, setInCarNumber] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const isLogged = Boolean(localStorage.getItem("isLoggedIn"))
    // To get data of parked cars
    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.get("http://localhost:5015/parkedCars");
                const response = await axios.get(`${ApiConfig.baseUrl}parkedCars`);

                setParkedCars(response.data);
            } catch (error) {
                console.error(error);
                // setParkedCars([{id:'1',OutTime:'1/29/2023, 3:36:45 PM',duration:'2 hr', BillAmount:'300', vehicleType:'Car', inCarNumber:'XC-VBN1-1234',inTime:'1/29/2023, 1:36:45 PM' },
                //                {id:'2',OutTime:'1/29/2023, 4:36:45 PM',duration:'2 hr', BillAmount:'300', vehicleType:'Scooter', inCarNumber:'XC-VBN1-1234',inTime:'1/29/2023, 2:36:45 PM' },
                //                {id:'1',OutTime:'1/30/2023, 5:36:45 PM',duration:'2 hr', BillAmount:'300', vehicleType:'Car', inCarNumber:'XC-VBN1-1234',inTime:'1/29/2023, 3:36:45 PM' },
                //                {id:'2',OutTime:'1/29/2023, 6:36:45 PM',duration:'2 hr', BillAmount:'300', vehicleType:'Scooter', inCarNumber:'XC-VBN1-1234',inTime:'1/29/2023, 4:36:45 PM' },
                //                {id:'1',OutTime:'1/29/2023, 7:36:45 PM',duration:'2 hr', BillAmount:'300', vehicleType:'Car', inCarNumber:'AB-CDEF-1234',inTime:'1/29/2023, 5:36:45 PM' },
                //                {id:'2',OutTime:'1/29/2023, 8:36:45 PM',duration:'2 hr', BillAmount:'300', vehicleType:'Scooter', inCarNumber:'XC-VBN1-1234',inTime:'1/29/2023, 6:36:45 PM' },
                //                {id:'1',OutTime:'1/29/2023, 9:36:45 PM',duration:'2 hr', BillAmount:'300', vehicleType:'Car', inCarNumber:'XC-VBN1-1234',inTime:'1/29/2023, 7:36:45 PM' },
                //                {id:'2',OutTime:'1/29/2023, 10:36:45 PM',duration:'2 hr', BillAmount:'300', vehicleType:'Scooter', inCarNumber:'XC-VBN1-1234',inTime:'1/29/2023, 8:36:45 PM' }]);
            }
        };
        fetchData();
    }, []);

    const [showBill, setShowBill] = useState(false);
    const [selectedBill, setSelectedBill] = useState({});
    const [totalBill, setTotalBill] = useState(0);

    const handleBill = (car) => {
        setSelectedBill(car);
        setShowBill(!showBill);
    };

    const handleClose = () => {
        setShowBill(!showBill);
    };
    
    const setSearchValue=(e)=>{
        e.preventDefault();
        const currentTime = new Date().toLocaleString();
        const inTime = currentTime;
        console.log(currentTime,'jj '+inTime)
        console.log(new Date(startTime).toLocaleString() , new Date(endTime).toLocaleString())
    }
    //setTotalBill = parkedCars.reduce((acc, car) => acc + car.BillAmount, 0);

    if (!isLogged) {
        return <Navigate to={"/"} replace={true} />
    }

    return (
        <div>
        <AttendentHeader/>
        <div className="row">
             <div className="form_head mt-2 d-flex" style={{    width: '92%', margin: '0px 4%'}}>
              <div className="float-left col-md-2 mt-1" style={{fontSize:'22px', color:'#010152'}}>Parking Bills</div>

             <div className="col-md-10 d-flex float-right" style={{fontSize:'12px',fontSize: '12px', position: 'static',    justifyContent: 'end'}}>
                <p className="mr-3 mt-2" style={{fontSize:'14px'}}>Total Bill Amount: {totalBill}</p>                
                <div>
                  
                </div>
             </div>
            </div>

            <div className="col-md-12 mt-2">
      <form onSubmit={setSearchValue}>
      <div  className="SlotMas_form pb-1">     
         <div className="form_body d-flex">
          <div className="d-flex">
          <label className="form-label mr-2 mt-2" id="vehicle-type">Date Range</label>
          <input type="datetime-local" style={{width:'188px' , fontSize:'13px'}} className="form-control mr-3" onChange={(event) => setStartTime(event.target.value)}  placeholder="Start Date" /> -   
          <input type="datetime-local" style={{width:'188px' , fontSize:'13px'}} className="form-control ml-3 mr-5" onChange={(event) => setEndTime(event.target.value)}  placeholder="End Date" />
          </div>
          <div className=" mr-4">
          <input className="form-control" style={{padding:'5px !important'}} value={inCarNumber}
          onChange={(event) => setInCarNumber(event.target.value)} 
          placeholder="Search by Vehicle Number"       />
          </div> 
          {/* <div  style={{textAlign:'center'}}> <button  className="btn btn_Primar pr-2 pl-2" type="submit">Filter</button></div> */}
      
        </div>
      </div>     
      </form>   
      </div>

            {/* <h1>Parking Bill</h1> */}
            <table id="parking" style={{margin:'10px 5%',width:'90%'}}>        
               <tr>
                 <th className="tb_cell">S. No.</th>
                 <th className="tb_cell">Vehicle Type</th>
                 <th className="tb_cell"> Number</th>
                 <th className="tb_cell">In Time</th>
                 <th className="tb_cell">Out Time</th>
                 <th className="tb_cell"> Duration</th>
                 <th className="tb_cell">Cost</th>
                 <th className="tb_cell">Action</th>
               </tr>       
               <tbody >
               { parkedCars.filter(
                 (car) =>
                   car &&
                   car.inTime >  new Date(startTime).toLocaleString()&& 
                   car.OutTime < new Date(endTime).toLocaleString() &&                   
                   car.inCarNumber.toLowerCase().includes(inCarNumber.toLowerCase())
               )
               .map((car,index)=>{
               return (
                 <tr className="tb-row" key={index}>
                    <td className="row_cell">{index + 1}</td>
                    <td className="row_cell">{car.vehicleType}</td>
                    <td className="row_cell">{car.inCarNumber}</td>
                    <td className="row_cell">{car.inTime}</td>
                    <td className="row_cell">{car.OutTime || 'N/A'}</td>
                    <td className="row_cell">{car.duration}</td>
                    <td className="row_cell">{car.BillAmount}</td>
                    <td className="row_cell">
                    <IconButton onClick={() => handleBill(car.id)}>
                    <PrintIcon className="print-icon" />                       
                    </IconButton>
                    {/* <button className="btn btn-warning" onClick={() => handleOut(car.id, car.inCarNumber, car.inTime)}  >
                        Park Out
                   </button> */}
                    </td>
                 </tr>
        )
        })}
        
        </tbody>
        </table>
        {/* <TableCell colSpan={6}>Total Bill Amount</TableCell>
        <TableCell>{totalBill}</TableCell> */}
            {/* <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S. No.</TableCell>
                            <TableCell>Vehicle Type</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>In Time</TableCell>
                            <TableCell>Out Time</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {parkedCars.filter(car => car.inTime && car.OutTime).map((car, index) => (
                            
                            <TableRow key={index}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{car.vehicleType}</TableCell>
                                <TableCell>{car.inCarNumber}</TableCell>
                                <TableCell>{car.inTime}</TableCell>
                                <TableCell>{car.OutTime || 'N/A'}</TableCell>
                                <TableCell>{car.duration}</TableCell>
                                <TableCell>{car.BillAmount}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleBill(car.id)}>
                                        Print
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
        <TableCell colSpan={6}>Total Bill Amount</TableCell>
        <TableCell>{totalBill}</TableCell>
      </TableRow>
                    </TableBody>
                </Table>
            </TableContainer> */}
        </div>
        </div>
    );
};
export default ParkingBill;