import React from 'react';
import { FaBell, FaCar, FaMapMarkerAlt, FaPhone } from 'react-icons/fa'; // Example icons
import { useNavigate } from 'react-router-dom';
import logo from './../../images/logo.png';

const Home = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    link.onerror = () => {
      console.error(`Failed to download file from ${url}`);
    };
  };

  const handleDownloadUserApp = () => {
    const userAppUrl = '/MoblieApkLink/user/app-release.apk';
    downloadFile(userAppUrl);
  };

  const handleDownloadGuardApp = () => {
    const guardAppUrl = '/MoblieApkLink/guard/app-release.apk';
    downloadFile(guardAppUrl);
  };

  return (
    <div className="home-container">
      <header>
        <div className="logo">
          <img src={logo} alt="PARK 360 Logo" />
          <span>PARK 360</span>
        </div>
        <nav>
          <button onClick={handleLogin} className="login-btn">Login</button>
        </nav>
      </header>

      <main>
        <section className="hero">
          <h1>Welcome to PARK 360</h1>
          <p>Discover and manage parking spaces efficiently.</p>
          <button onClick={handleDownloadUserApp} className="download-btn">Download User App</button>
          <button onClick={handleDownloadGuardApp} className="download-btn">Download Guard App</button>
        </section>

        <section className="features">
          <h2>Features</h2>
          <div className="feature-items">
            <div className="feature-item">
              <FaCar size={40} />
              <h3>Smart Parking</h3>
              <p>Find and reserve parking spots easily with our smart system.</p>
            </div>
            <div className="feature-item">
              <FaMapMarkerAlt size={40} />
              <h3>Real-time Location</h3>
              <p>Get real-time updates on parking space availability.</p>
            </div>
            <div className="feature-item">
              <FaBell size={40} />
              <h3>Instant Notifications</h3>
              <p>Receive alerts and notifications for parking space updates.</p>
            </div>
            <div className="feature-item">
              <FaPhone size={40} />
              <h3>24/7 Support</h3>
              <p>Our support team is available around the clock to assist you.</p>
            </div>
          </div>
        </section>

        {/* <section className="testimonials">
          <h2>What Our Users Say</h2>
          <div className="testimonial-item">
            <p>"PARK 360 has revolutionized my parking experience. Highly recommended!"</p>
            <cite>- John Doe</cite>
          </div>
          <div className="testimonial-item">
            <p>"The user-friendly interface and real-time updates are fantastic."</p>
            <cite>- Jane Smith</cite>
          </div>
        </section> */}

        
      </main>

      {/* <footer>
        <p>&copy; 2024 PARK 360. All rights reserved.</p>
        <nav>
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
        </nav>
      </footer> */}
    </div>
  );
};

export default Home;
