import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Login from './components/Admin/LoginPage';
import ParkingBill from './components/Attendent/ParkingBill';
import ParkingComponent from './components/Attendent/ParkingComponent';
import VehicleTypeMaster from './components/Attendent/VehicleTypeMaster';
import CustomerInformation from './components/Customer_Portal/Cust_Info';
import InboxPage from './components/Customer_Portal/InboxPage';
import NotificationPage from './components/Customer_Portal/NotificationPage';
import TestChat from './components/Customer_Portal/TestChat';
import VisitorEntry from './components/Customer_Portal/VisitorEntry';
import CustomerLogin from './components/Customer_Portal/cust_Login';
import GatekeeperVisitorRequest from './components/Gatekeeper/GatekeeperVisitorRequest';
import AddFlat from './components/Society_Portal/AddFlat';
import ParkingRegistrationForm from './components/Society_Portal/ParkingRegistrationForm';
import ParkingSlotMaster from './components/Society_Portal/ParkingSlotMaster';
import ParkingDetails from './components/Society_Portal/Parking_Details';
import ViewFlats from './components/Society_Portal/ViewFlats';
import AddUser from './components/Society_Portal/addUser';
import UserManagement from './components/Society_Portal/userManagement';
// import { AuthProvider } from './components/context/AuthContext';
import { AuthProvider } from '../src/context/AuthContext';
import Home from "./components/Admin/home";
import UploadData from "./components/Admin/uploadData";
import Chat from "./components/Customer_Portal/tc";
import AdminList from "./components/SuperAdmin/adminList";
import LevelForm from "./components/publicParking/LevelForm";
import ManageAttendent from "./components/publicParking/manage-attendent";



function App() {
  return (
    <AuthProvider>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/parking-settings" element={<LevelForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/VehicleTypeMaster" element={<VehicleTypeMaster />} />
            <Route path="/parkingSlotMaster" element={<ParkingSlotMaster />} />
            <Route path="/admin" element={<AdminList />} />
            <Route path="/parking-details" element={<ParkingDetails />} />
            <Route path="/parking-registration" element={<ParkingRegistrationForm />} />
            <Route path="/parking-module" element={<ParkingComponent />} />
            <Route path="/parking-bill" element={<ParkingBill />} />
            <Route path="/notification-page" element={<NotificationPage />} />
            <Route path="/inbox-page" element={<InboxPage />} />
            <Route path="/test-chat" element={<TestChat />} />
            <Route path="/visitor-request" element={<GatekeeperVisitorRequest />} />
            <Route path="/visitor-entry" element={<VisitorEntry />} />
            <Route path="/customer-login" element={<CustomerLogin />} />
            <Route path="/customer-information" element={<CustomerInformation />} />
            <Route path="/attendent" element={<ManageAttendent />} />
            <Route path="/add-flat" element={<AddFlat />} />
            <Route path="/view-flats" element={<ViewFlats />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/add-user" element={<AddUser />} />
            <Route path="/tc" element={<Chat />} />
            <Route path="/" element={<Home />} />
            <Route path="/upload-data" element={<UploadData />} />

          </Routes>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
