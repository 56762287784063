import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import ApiConfig from '../ApiConfig';
import SocietyHeader from './SocietyHeader';

import './table.css';

function Parking_Details() {

  const [isEdit, EditRecord] = useState(false);
  const [slotNumber, setLotNum] = useState("");
  const [vehicleNumber, setCarRegNum] = useState("");
  const [flatNumber, setFlatNum] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerContact, setCont] = useState('');
  const [towerName, setTower] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setParkUpdateError] = useState('');
  const [data, setData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [vehicleType, setVehicleType] = useState('');
  const [status, setStatus] = useState('');
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiConfig.baseUrl}parkingAllotmentView`);
        setData(Array.isArray(response.data) ? response.data : [response.data]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleUpdateSubmit = async (id) => {
    if (slotNumber == '' || vehicleNumber == '' || flatNumber == '' || ownerName == '' ||
      ownerContact == '' || vehicleType === '' || errorMsg.type) {
      setHasError(true)
    } else {
      console.log("vehicleType update:", vehicleType);
      try {
        await axios.put(
          `${ApiConfig.baseUrl}parkingAllotmentUpdate/${data[editingIndex].id}`,
          { slotNumber, vehicleNumber, towerName, flatNumber, ownerName, ownerContact, vehicleType, status }
        );
        const updatedData = [...data];
        updatedData[editingIndex] = { slotNumber, vehicleNumber, towerName, flatNumber, ownerName, ownerContact, vehicleType, status };
        setData(updatedData);
        alert("Record updated successfully!");
        setEditingIndex(-1);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleUpdate = (item, index) => {
    setLotNum(item.slotNumber);
    setCarRegNum(item.vehicleNumber);
    setFlatNum(item.flatNumber);
    setOwnerName(item.ownerName);
    setCont(item.ownerContact);
    setTower(item.towerName);
    setVehicleType(item.vehicleType);
    setStatus(item.status);
    EditRecord(true);
    setEditingIndex(index);
  };

  const handleBlockSlot = async (index) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to Block the Vehicle: ${data[index].vehicleNumber}?`
    );
    if (confirmDelete) {
      try {
        const id = data[index].id;
        await axios.put(`${ApiConfig.baseUrl}delete/${id}`);
        // const updatedData = data.filter((_, i) => i !== index);  // Remove the item from the UI
        // setData(updatedData);
        // setData(fetchData());
        const response = await axios.get(`${ApiConfig.baseUrl}parkingAllotmentView`);
        setData(Array.isArray(response.data) ? response.data : [response.data]);

        alert("Record marked as blocked successfully!");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSlotNumBlur = async (event) => {
    const { value } = event.target;

    try {
      const response = await axios.get(`${ApiConfig.baseUrl}parkingAllotmentSlot/${value}`);
      if (response.data) {
        setLotNum('');
        setParkUpdateError(
          { type: 'slotNum', msg: `Slot Number ${value} is already booked. Please enter a different Slot Number.` }
        );
      } else {
        setParkUpdateError("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDupVehicalBlur = async (event) => {
    const { value } = event.target;
    try {
      const response = await axios.get(`${ApiConfig.baseUrl}parkingAllotmentVehicle/${value}`);
      if (response.data) {
        setCarRegNum('');
        setParkUpdateError(
          { type: 'vehicalNum', msg: `Vehicle Number ${value} is already registered. Please enter a different vehicle number.` }
        );
      } else {
        setParkUpdateError("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cancelUpdate = () => {
    setLotNum('');
    setCarRegNum('');
    setFlatNum('');
    setOwnerName('');
    setCont('');
    setTower('');
    setVehicleType('');
    setStatus('');
    EditRecord(false);
    setEditingIndex(-1);
  }

  const renderErrorMessage = (type) =>
    type === errorMsg.type && (
      <div className="error">{errorMsg.msg}</div>
    );


    if (!isLogged) {
      return <Navigate to={"/"} replace={true} />
  }
  return (
    <div>
      <SocietyHeader />
      <div className='row'>
        {!isEdit && (
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div style={{ backgroundColor: '#e3930b', height: '60px', color: 'white', padding: '12px 10px', margin: '10px' }}>
              <div style={{ float: 'left', fontWeight: '600', fontSize: '24px' }}>Parking Details</div>
              <div style={{ float: 'right' }}><Link to="/parking-registration"><button className='btn_Primar'>Add Parking Registration</button></Link></div>
            </div>
            <div className="table-wrapper">
            <table id="parking" style={{ margin: '10px 10%', width: '79%' }}>
              <thead>
                <tr>
                  <th className="tb_cell">S. No.</th>
                  <th className="tb_cell">Tower Name</th>
                  <th className="tb_cell">Flat Number</th>
                  <th className="tb_cell">Slot Number</th>
                  <th className="tb_cell">Vehicle Type</th>
                  <th className="tb_cell">Vehicle Number</th>
                  <th className="tb_cell">Owner Name</th>
                  <th className="tb_cell">Owner Contact</th>
                  <th className="tb_cell">Status</th>
                  <th className="tb_cell"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index} className="tb-row">
                      <td className="row_cell">{index + 1}</td>
                      <td className="row_cell">{item.towerName}</td>
                      <td className="row_cell">{item.flatNumber}</td>
                      <td className="row_cell">{item.slotNumber}</td>
                      <td className="row_cell">{item.vehicleType}</td>
                      <td className="row_cell">{item.vehicleNumber}</td>
                      <td className="row_cell">{item.userName}</td>
                      <td className="row_cell">{item.userContact}</td>
                      <td className="row_cell">{item.status}</td>
                      <td className="row_cell">
                        <IconButton onClick={() => handleUpdate(item, index)}>
                          <EditIcon className="edit-icon" />
                        </IconButton>
                        <IconButton onClick={() => handleBlockSlot(index)}>
                          <BlockIcon className="delete-icon" />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </div>
          </div>
        )}

        {isEdit && (
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='row'>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <div style={{ backgroundColor: '#e3930b', height: '50px', color: 'white', padding: '10px', margin: '10px' }}>
                  <div style={{ float: 'left', fontWeight: '600', fontSize: '18px' }}>Parking Details</div>
                  <div style={{ float: 'right' }}><Link to="/parking-registration"><button className='btn_Primar'>Add Parking Registration</button></Link></div>
                </div>
                <div className="table-wrapper">
                <table id="parking" style={{ margin: '10px 10%', width: '79%' }}>
                  <thead>
                    <tr>
                      <th className="tb_cell">S. No.</th>
                      <th className="tb_cell">Tower Name</th>
                      <th className="tb_cell">Flat Number</th>
                      <th className="tb_cell">Slot Number</th>
                      <th className="tb_cell">Vehicle Type</th>
                      <th className="tb_cell">Vehicle Number</th>
                      <th className="tb_cell">Owner Name</th>
                      <th className="tb_cell">Owner Contact</th>
                      <th className="tb_cell">Status</th>
                      <th className="tb_cell"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tb-row">
                      <td className="row_cell">{editingIndex + 1}</td>
                      <td className="row_cell">
                        <input type="text" value={towerName} onChange={(e) => setTower(e.target.value)} />
                      </td>
                      <td className="row_cell">
                        <input type="text" value={flatNumber} onChange={(e) => setFlatNum(e.target.value)} />
                      </td>
                      <td className="row_cell">
                        <input type="text" value={slotNumber} onBlur={handleSlotNumBlur} onChange={(e) => setLotNum(e.target.value)} />
                        {renderErrorMessage('slotNum')}
                      </td>
                      <td className="row_cell">
                        <input type="text" value={vehicleType} onChange={(e) => setVehicleType(e.target.value)} />
                      </td>
                      <td className="row_cell">
                        <input type="text" value={vehicleNumber} onBlur={handleDupVehicalBlur} onChange={(e) => setCarRegNum(e.target.value)} />
                        {renderErrorMessage('vehicalNum')}
                      </td>
                      <td className="row_cell">
                        <input type="text" value={ownerName} onChange={(e) => setOwnerName(e.target.value)} />
                      </td>
                      <td className="row_cell">
                        <input type="text" value={ownerContact} onChange={(e) => setCont(e.target.value)} />
                      </td>
                      <td className="row_cell">
                        <input type="text" value={status} onChange={(e) => setStatus(e.target.value)} />
                      </td>
                      <td className="row_cell">
                        <IconButton onClick={() => handleUpdateSubmit(data[editingIndex].id)}>
                          <EditIcon className="edit-icon" />
                        </IconButton>
                        <IconButton onClick={cancelUpdate}>
                          <DeleteIcon className="delete-icon" />
                        </IconButton>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Parking_Details;
