import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';


const VehicleTypeMaster = () => {
  const [classType, setClassType] = useState("");
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Selected class:", classType);
    // You can also send the data to the server here
  }

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}


  return (
    <form onSubmit={handleSubmit}>
      <label>
        Select vehicle class:
        <select value={classType} onChange={e => setClassType(e.target.value)}>
          <option value="">Please select a class</option>
          <option value="Sedan">Sedan</option>
          <option value="SUV">SUV</option>
          <option value="Truck">Truck</option>
        </select>
      </label>
      <input type="submit" value="Submit" />
    </form>
  );
}

export default VehicleTypeMaster;
