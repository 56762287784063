import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import io from "socket.io-client";
import ApiConfig from '../ApiConfig';
import Header from '../header';
import './../../App.css';



const socket = io(`${ApiConfig.socketUrl1}`, { transports: ['websocket'] });



function GatekeeperVisitorRequest() {
  const [searchValue, setSearchValue] = useState("");
  const [lastPermission, setLastPermission] = useState('');
  const [notification, setNotification] = useState({});
  const [message, setMessage] = useState('');
  const [data, setData] = useState([]);
  const [visiter, setvisiter] = useState(false); 
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))

  const [values, setValues] = useState({
    flatNumber: "",
    visitorName: "",
    visitorVehicle: "",
    visitorContact: "",
    flatUserId:''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiConfig.baseUrl}parkingAllotmentView`);
        setData(Array.isArray(response.data) ? response.data : [response.data]);
       
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // save the visitor permission object to the database with the residentUserId
      //await axios.post('http://localhost:5015/api/visitor/log-permission', {
      let value={
        flatNumber: values.flatNumber,
        visitorName: values.visitorName,
        flatUserId:  values.flatUserId,
        visitorVehicle: values.visitorVehicle,
        visitorContact: values.visitorContact,
        permit: null
      }
      console.log(value)
      await axios.post(`${ApiConfig.baseUrl}log-permission`, {
        flatNumber: values.flatNumber,
        visitorName: values.visitorName,
        flatUserId:  values.flatUserId,
        visitorVehicle: values.visitorVehicle,
        visitorContact: values.visitorContact,
        permit: null
      }).then(handleNotification);
handleRefresh();
     
    } catch (err) {
      console.error(err);
      alert('Error logging permission.');
    }
  };

  const handleRefresh = async () => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/visitor/get-last-permission`);
      const data = await response.json();

      setLastPermission(data);
    } catch (err) {
      console.error(err);
      alert('Error getting last permission.');
    }
  };

  useEffect(() => {
    //handleRefresh();
    socket.on('permission_response', (data) => {
      setNotification(data);
    });

    socket.on('permission_update', (data) => {
      console.log(data);
      setNotification(data);      // Update the UI with the new permission status
    });
  }, []);

  function handleNotification(response) {
    handleEmitData()
    setNotification(response.data);
    const data = response.data;
    socket.emit('permission_request', data);
    setvisiter(false)
  }

  function handleEmitData() {
    //axios.get(`http://localhost:5015/api/visitor/get-last-permission`)
    
    axios.get(`${ApiConfig.baseUrl}request`)
      .then(response => {
        const data = response.data;
        setMessage(data);
        // to send data to socket server
        socket.emit("send_message", JSON.stringify(data));
        //console.log('message sending to server',data);
      })
      .catch(error => {
        console.log(error);
        alert('Error retrieving messages');
      });
  }
  const raiseVisitorReq=(item)=>{
    setvisiter(true)
    setValues({
      flatNumber: item.flatNumber,
      visitorName: "",
      visitorVehicle: "",
      visitorContact: "",
      flatUserId:item.residentUserId
    })
    
   console.log(item)
  }


  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}

  return (
    <div>
    <Header />
    <div style={{backgroundColor:'#e3930b',height:'60px',color:'white',padding: '12px 10px',  margin: '10px'}}>
          <div style={{float:'left',fontWeight:'600',fontSize:'24px'}}>Parking Details</div>
          <div style={{float:'right'}}>
           <input type="text" className="form-control" onChange={(e) => setSearchValue(e.target.value)} placeholder="Search"/></div>
          </div>

      {!visiter &&
         (<div className='col-md-12'>
           <table id="parking" style={{margin:'10px 6%',width:'84%'}}>
             <tr>
               <th className="tb_cell">Lot Number</th>
               <th className="tb_cell">Car Registration Number</th>
               <th className="tb_cell">Flat Number</th>
               <th className="tb_cell">Owner's Name</th>
               <th className="tb_cell">Contact Number</th>
               <th className="tb_cell"></th>
             </tr>       
           <tbody >
           { data.filter((item)=> item && (item.slotNumber.toLowerCase().includes(searchValue.toLowerCase())
           || item.vehicleNumber.toLowerCase().includes(searchValue.toLowerCase() )
           || item.flatNumber.toLowerCase().includes(searchValue.toLowerCase())
           || item.ownerName.toLowerCase().includes(searchValue.toLowerCase())
           || item.ownerContact.toLowerCase().includes(searchValue.toLowerCase())
            ))
           .map((item,index)=>{
             return (
               <tr className="tb-row">
                  <td className="row_cell">{item.slotNumber}</td>
                  <td className="row_cell">{item.vehicleNumber}</td>
                  <td className="row_cell">{item.flatNumber}</td>
                  <td className="row_cell">{item.ownerName}</td>
                  <td className="row_cell">{item.ownerContact}</td>
                  <td className="row_cell">                
                  <button onClick={()=>raiseVisitorReq(item)} className="btn btn_Primar">Raise Visitor Request</button>
                  </td>
               </tr>
             )
           })}
             
           </tbody>
         </table>
         </div>)
      }
      { visiter &&
        ( <div className='row'>
           <div className='col-md-8'>
           <table id="parking" style={{margin:'10px 6%',width:'88%'}}>        
             <tr>
               <th className="tb_cell">Lot Number</th>
               <th className="tb_cell">Car Registration Number</th>
               <th className="tb_cell">Flat Number</th>
               <th className="tb_cell">Owner's Name</th>
               <th className="tb_cell">Contact Number</th>
               <th className="tb_cell"></th>
             </tr>       
           <tbody >
           { data.map((item,index)=>{
             return (
               <tr className="tb-row">
                  <td className="row_cell">{item.slotNumber}</td>
                  <td className="row_cell">{item.vehicleNumber}</td>
                  <td className="row_cell">{item.flatNumber}</td>
                  <td className="row_cell">{item.ownerName}</td>
                  <td className="row_cell">{item.ownerContact}</td>
                  <td className="row_cell">                
                  <button onClick={()=>raiseVisitorReq(item)} className="btn btn_Primar">Raise Visitor Request</button>
                  </td>
               </tr>
             )
           })}
             
           </tbody>
         </table>
           </div>
           <div className='col-md-4'>
           <form onSubmit={handleSubmit}>
           <div  className="SlotMas_form">
              <div className="form_head" > Visitor Request Form </div>
                {/* {hasError?<div style={{color:'red',textAlign:'center'}}>Please enter required field (*)</div>:'' } */}
                 <div className="form_body">
                 <label className="form-label mb-3">
                   Flat Number:
                   <input type="text" className="form-control"  value={values.flatNumber} onChange={handleInputChange} name="flatNumber" />
                 </label>
                 <br />
                 <label className="form-label mb-3">
                   Visitor Name:
                   <input type="text" className="form-control" value={values.visitorName} onChange={handleInputChange} name="visitorName"/>
                 </label>
                 <br />
                 <label className="form-label mb-3">
                   Visitor Vehicle:
                   <input type="text" className="form-control" value={values.visitorVehicle} onChange={handleInputChange} name="visitorVehicle" />
                 </label>
                 <br />
                 <label className="form-label mb-3">
                   Visitor Contact:
                   <input type="text" className="form-control" value={values.visitorContact} onChange={handleInputChange} name="visitorContact" />
                 </label>
        <br />
        <button type="submit" className="btn btn-success">Send</button>
        </div>
        </div>
      </form>

      <button onClick={handleRefresh}>Refresh</button>
      <br />
      Last permission: {lastPermission ? JSON.stringify(lastPermission) : 'none'}
      <br />
      Notification: {JSON.stringify(notification)}
           </div>
        </div>)
      }




      {/* <form onSubmit={handleSubmit}>
        <label>
          Flat Number:
          <input type="text" value={flatNumber} onChange={(e) => setFlatNumber(e.target.value)} />
        </label>
        <br />
        <label>
          Visitor Name:
          <input type="text" value={visitorName} onChange={(e) => setVisitorName(e.target.value)} />
        </label>
        <br />
        <label>
          Visitor Vehicle:
          <input type="text" value={visitorVehicle} onChange={(e) => setVisitorVehicle(e.target.value)} />
        </label>
        <br />
        <label>
          Visitor Contact:
          <input type="text" value={visitorContact} onChange={(e) => setVisitorContact(e.target.value)} />
        </label>
        <br />
        <button type="submit">Send</button>
      </form>

      <button onClick={handleRefresh}>Refresh</button>
      <br />
      Last permission: {lastPermission ? JSON.stringify(lastPermission) : 'none'}
      <br />
      Notification: {JSON.stringify(notification)} */}
    </div>
  );
}

export default GatekeeperVisitorRequest;
