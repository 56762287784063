// UserManagement.js

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Tabs,
  TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import ApiConfig from '../ApiConfig';

import AddUser from './addUser';
import SocietyHeader from './SocietyHeader';
// import './table.css';


const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [parkingSlots, setParkingSlots] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [towers, setTowers] = useState([]);
  const [flats, setFlats] = useState([]);
  const [selectedParkingSlots, setSelectedParkingSlots] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState('');
  const [selectedTower, setSelectedTower] = useState('');
  const [selectedFlats, setSelectedFlats] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const [usersDetails, setUsersDetails] = useState([]);
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [permissionDialogOpen, setPermissionDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))




  useEffect(() => {
    fetchUsers();
    fetchBlocks();
    fetchUsersDetails();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/users`);
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchUsersDetails = async () => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/details/allUsers`);
      const data = await response.json();

      if (response.ok) {
        setUsersDetails(data);
      } else {
        console.error('Error:', data.error || 'Unknown error');
      }
    } catch (error) {
      console.error('Error fetching users details:', error);
    }
  };

  const fetchBlocks = async () => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/blocks`);
      const data = await response.json();
      setBlocks(data);
    } catch (error) {
      console.error('Error fetching blocks:', error);
    }
  };

  const fetchTowers = async (blockName) => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/towers/${blockName}`);
      const data = await response.json();
      setTowers(data);
    } catch (error) {
      console.error('Error fetching towers:', error);
    }
  };

  const fetchFlats = async (blockName, towerName) => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/flats/${blockName}/${towerName}`);
      const data = await response.json();
      setFlats(data);
    } catch (error) {
      console.error('Error fetching flats:', error);
    }
  };

  const fetchAvailableSlots = async (blockName, towerName) => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/getAvailableSlots?block_name=${blockName}&tower_name=${towerName}`);
      const data = await response.json();
      setParkingSlots(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching available slots:', error);
    }
  };

  const handleLinkButtonClick = (user) => {
    setSelectedUser(user);
    setLinkDialogOpen(true);
  };

  const handleDialogClose = () => {
    setLinkDialogOpen(false);
    setSelectedUser(null);
    setSelectedFlats([]);
    setSelectedParkingSlots([]);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleLink = async () => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/link/${selectedUser.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          parkingSlots: selectedParkingSlots,
          block: selectedBlock,
          tower: selectedTower,
          flats: selectedFlats,
        }),
      });

      if (response.ok) {
        alert('User linked successfully');
        setSelectedUser(null);
        setSelectedFlats([]);
        setSelectedParkingSlots([]);
      } else {
        alert('Error linking user');
      }
    } catch (error) {
      console.error('Error linking user:', error);
    }
  };

  const handleBlockChange = (event) => {
    const blockName = event.target.value;
    setSelectedBlock(blockName);
    fetchTowers(blockName);
    setSelectedTower('');
    setSelectedFlats([]);
    setParkingSlots([]);
  };

  const handleTowerChange = (event) => {
    const towerName = event.target.value;
    setSelectedTower(towerName);
    fetchFlats(selectedBlock, towerName);
    fetchAvailableSlots(selectedBlock, towerName);
    setSelectedFlats([]);
    setParkingSlots([]);
  };

  const handleParkingSlotsChange = (event) => {
    setSelectedParkingSlots(event.target.value);
  };

  const handleFlatsChange = (event) => {
    setSelectedFlats(event.target.value);
  };

  // const filteredUsers = usersDetails.filter((userDetail) => {
  //   const user = userDetail.user;
  //   if (tabIndex === 0) {
  //     return user.userType === 2 && user.parkingType === 2;
  //   }
  //   if (tabIndex === 1) {
  //     return user.userType === 1 && user.parkingType === 2;
  //   }
  //   return false;
  // });

  const filteredUsers = usersDetails.filter((userDetail) => {
    const user = userDetail.user;
    const hasMatchingVehicle = userDetail.flats.some(flat =>
      flat.vehicles.some(vehicle =>
        vehicle.vehicleNumber.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  
    if (searchTerm && !hasMatchingVehicle) {
      return false;
    }
  
    if (tabIndex === 0) {
      return user.userType === 2 && user.parkingType === 2;
    }
    if (tabIndex === 1) {
      return user.userType === 1 && user.parkingType === 2;
    }
    return false;
  });
  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
  }

  return (
    <div>
      <SocietyHeader />
      <div style={{ backgroundColor: '#e3930b', height: '60px', color: 'white', padding: '12px 10px', margin: '10px' }}>
        <div style={{ float: 'left', fontWeight: '600', fontSize: '24px' }}>User Management</div>
        <div style={{ float: 'right' }}>
          <Button variant="contained" color="primary" onClick={() => setAddUserDialogOpen(true)}>
            Add User
          </Button>
        </div>
      </div>
      <Tabs value={tabIndex} onChange={(e, newIndex) => setTabIndex(newIndex)}>
        <Tab label="Members" />
        <Tab label="Admin" />
      </Tabs>
      <div style={{ margin: '20px 0' }}>
  <TextField
    label="Search by Vehicle Number"
    variant="outlined"
    value={searchTerm}
    onChange={handleSearch}
    fullWidth
  />
</div>
      <TableContainer component={Paper} style={{ maxHeight: 1000, overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Parking Slots</TableCell>
              <TableCell>Flats</TableCell>
              <TableCell>Vehicle Type</TableCell>
              <TableCell>Vehicle Number</TableCell>
              {tabIndex === 0 && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((userDetail) => (
              <TableRow key={userDetail.user.id}>
                <TableCell>{userDetail.user.id}</TableCell>
                <TableCell>{userDetail.user.userName}</TableCell>
                <TableCell>{userDetail.user.userContact}</TableCell>
                <TableCell>{userDetail.user.userEmail}</TableCell>
                <TableCell>
                  {userDetail.flats.length > 0
                    ? userDetail.flats.map(flat =>
                      flat.parkingSlots.length > 0
                        ? flat.parkingSlots.map(slot => slot.slot_info).join(', ')
                        : 'N/A'
                    ).join(', ')
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  {userDetail.flats.length > 0
                    ? userDetail.flats.map(flat => flat.flat.flat_number).join(', ')
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  {userDetail.flats.length > 0
                    ? userDetail.flats.flatMap(flat =>
                      flat.vehicles.map(vehicle => vehicle.vehicleType)
                    ).join(', ')
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  {userDetail.flats.length > 0
                    ? userDetail.flats.flatMap(flat =>
                      flat.vehicles.map(vehicle => vehicle.vehicleNumber)
                    ).join(', ')
                    : 'N/A'}
                </TableCell>
                {tabIndex === 0 && (
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleLinkButtonClick(userDetail.user)}
                    >
                      Link
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={linkDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Link Parking Slots and Flats to User</DialogTitle>
        <DialogContent>
          {selectedUser && (
            <>
              <p>User Name: {selectedUser.userName}</p>
              <p>User Contact: {selectedUser.userContact}</p>
              <p>User Email: {selectedUser.userEmail}</p>
              <FormControl fullWidth margin="normal">
                <InputLabel>Block</InputLabel>
                <Select value={selectedBlock} onChange={handleBlockChange}>
                  {blocks.map((block) => (
                    <MenuItem key={block.block_name} value={block.block_name}>
                      {block.block_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tower</InputLabel>
                <Select value={selectedTower} onChange={handleTowerChange} disabled={!selectedBlock}>
                  {towers.map((tower) => (
                    <MenuItem key={tower.tower_name} value={tower.tower_name}>
                      {tower.tower_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Parking Slots</InputLabel>
                <Select
                  multiple
                  value={selectedParkingSlots}
                  onChange={handleParkingSlotsChange}
                  renderValue={(selected) => selected.join(', ')}
                  disabled={!selectedTower}
                >
                  {parkingSlots.map((slot) => (
                    <MenuItem key={slot.id} value={slot.slot_info}>
                      <Checkbox checked={selectedParkingSlots.indexOf(slot.slot_info) > -1} />
                      <ListItemText primary={slot.slot_info} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Flats</InputLabel>
                <Select
                  multiple
                  value={selectedFlats}
                  onChange={handleFlatsChange}
                  renderValue={(selected) => selected.join(', ')}
                  disabled={!selectedTower}
                >
                  {flats.map((flat) => (
                    <MenuItem key={flat.id} value={flat.flat_number}>
                      <Checkbox checked={selectedFlats.indexOf(flat.flat_number) > -1} />
                      <ListItemText primary={flat.flat_number} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLink} color="primary" disabled={!selectedBlock || !selectedTower}>
            Link
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add User Dialog */}
      <Dialog open={addUserDialogOpen} onClose={() => setAddUserDialogOpen(false)}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <AddUser />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddUserDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserManagement;
