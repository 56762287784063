import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { createTheme } from '@mui/material/styles';
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import ApiConfig from "../ApiConfig";
import './ParkingSlotMaster.css';
import SocietyHeader from "./SocietyHeader";


const theme = createTheme({
  spacing: 8,
});

const ParkingSlotMaster = () => {
  const [level, setLevel] = useState("");
  const [lanes, setLanes] = useState("");
  const [laneSlots, setLaneSlots] = useState([]);
  const [slots, setSlots] = useState([]);
  const [slotsPerLane, setSlotsPerLane] = useState([]);
  const [data, setData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [levelError, setLevelError] = useState("");
  const [isGenerated, setIsGenerated] = useState(false);
  const [blockNames, setBlockNames] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [towerNames, setTowerNames] = useState([]);
  const [selectedTower, setSelectedTower] = useState("");
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiConfig.baseUrl}parkingslots`);
        const parsedData = response.data.map(item => ({
          ...item,
          slots: JSON.parse(item.slots)
        }));
        setData(Array.isArray(parsedData) ? parsedData : [parsedData]);
        console.log("Fetched data: ", parsedData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    fetchBlockNames();
  }, []);

  const fetchBlockNames = async () => {
    try {
      const response = await axios.get(`${ApiConfig.baseUrl}api/societyManagement/blocks`);
      setBlockNames(response.data);
      console.log("Fetched block names: ", response.data); 
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTowerNames = async (blockName) => {
    try {
      const response = await axios.get(`${ApiConfig.baseUrl}api/societyManagement/towers/${blockName}`);
      setTowerNames(response.data);
      console.log("Fetched tower names for block: ", blockName, response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = () => {
    setLevel("");
    setLanes("");
    setLaneSlots([]);
    setSlots([]);
    setEditingIndex(-1);
    setLevelError("");
    setIsGenerated(false);
    setSelectedBlock("");
    setSelectedTower("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted"); // Log form submission
    console.log("Current state before submit: ", {
      level, lanes, slots, slotsPerLane, selectedBlock, selectedTower
    });

    if (editingIndex === -1) {
      try {
        const response = await axios.get(
          `${ApiConfig.baseUrl}levels/${level}`
        );
        if (response.data) {
          setLevelError(
            "Level already exists. Please enter a different level."
          );
          console.log("Level exists error");
          return;
        }
      } catch (error) {
        console.error(error);
      }
      try {
        const response = await axios.post(`${ApiConfig.baseUrl}save`, {
          level,
          lanes,
          slots: JSON.stringify(slotsPerLane),
          block_name: selectedBlock,
          tower_name: selectedTower,
        });
        setData([...data, { ...response.data, slots }]);
        setLevelError("");

        const savedId = response.data.id;

        // Save all slots
        await axios.post(`${ApiConfig.baseUrl}api/societyManagement/saveSlots`, {
          id: savedId,
          slots
        });

        alert('Data saved successfully!');
        console.log("Data saved successfully");
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await axios.get(
          `${ApiConfig.baseUrl}levels/${level}`
        );
      } catch (error) {
        console.error(error);
      }
      // updating the record
      try {
        await axios.put(
          `${ApiConfig.baseUrl}update/${data[editingIndex].id}`,
          { level, lanes, slots: JSON.stringify(slotsPerLane), block_name: selectedBlock, tower_name: selectedTower }
        );
        const updatedData = [...data];
        updatedData[editingIndex] = { level, lanes, slots };
        setData(updatedData);
        alert("Record updated successfully!");
        setEditingIndex(-1);
        console.log("Record updated successfully");
      } catch (error) {
        console.error(error);
      }
    }
    handleReset();
  };

  const handleSlotChange = (event, index) => {
    const updatedSlots = [...slots];
    updatedSlots[index] = event.target.value;
    setSlots(updatedSlots);
    console.log("Slot changed: ", updatedSlots);
  };

  const handleSlotsPerLaneChange = (event, index) => {
    const updatedSlotsPerLane = [...slotsPerLane];
    updatedSlotsPerLane[index] = event.target.value;
    setSlotsPerLane(updatedSlotsPerLane);
    console.log("Slots per lane changed: ", updatedSlotsPerLane); 
  };

  const handleLaneSlotChange = (event, index) => {
    const updatedLaneSlots = [...laneSlots];
    updatedLaneSlots[index] = event.target.value;
    setLaneSlots(updatedLaneSlots);
  };

  const handleDeleteSlot = (index) => {
    const updatedSlots = [...slots];
    updatedSlots.splice(index, 1);
    setSlots(updatedSlots);
    console.log("Slot deleted: ", updatedSlots);
  };

  const handleUpdate = (index) => {
    const { level, lanes, slots, block_name, tower_name } = data[index];
    setLevel(level);
    setLanes(lanes);
    setSlots(slots);
    setEditingIndex(index);
    setSelectedBlock(block_name);
    setSelectedTower(tower_name);
    console.log("Update initiated for index: ", index);
  };

  const handleDelete = async (index) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete level ${data[index].level}?`
    );
    if (confirmDelete) {
      try {
        const id = data[index].id;
        await axios.delete(`${ApiConfig.baseUrl}delete/${id}`);
        const updatedData = [...data];
        updatedData.splice(index, 1);
        setData(updatedData);
        alert("Record deleted successfully!");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleLevelBlur = async (event) => {
    const { value } = event.target;
    try {
      const response = await axios.get(`${ApiConfig.baseUrl}levels/${value}`);
      if (response.data) {
        setLevelError(
          `Level ${value} already exists. Please enter a different level.`
        );
        console.log("Level exists error on blur");
      } else {
        setLevelError("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const generateSlotNumbers = () => {
    const totalSlots = laneSlots.reduce((acc, slots) => acc + parseInt(slots, 10), 0);
    const newSlots = Array.from({ length: totalSlots }, (_, i) => `S${(i + 1).toString().padStart(2, '0')}`);
    setSlots(newSlots);
    setIsGenerated(true);
    console.log("Slot numbers generated: ", newSlots); 
  };

  const saveSlots = async () => {
    try {
      const response = await axios.post(`${ApiConfig.baseUrl}api/societyManagement/saveSlots`, {
        level,
        slots: JSON.stringify(slots),
      });

      if (response.status === 200) {
        alert('Slots saved successfully!');
        handleReset();
        console.log("Slots saved successfully");
      } else {
        throw new Error('Failed to save slots');
      }
    } catch (error) {
      console.error('Error saving slots:', error);
      alert('Failed to save slots. Please try again.');
    }
  };

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}

  return (
    <div>
      <SocietyHeader />
      <form onSubmit={handleSubmit}>
        <div className="SlotMas_form">
          <div className="form_head">Parking Slot Master</div>
          <div className="form_body">
            <div className="form_field" style={{ display: 'flex', justifyContent: 'space-between' , marginLeft:'20px' }}>
              <FormControl style={{ width: '48%' }}>
                <InputLabel>Block Name</InputLabel>
                <Select
                  value={selectedBlock}
                  onChange={(event) => {
                    setSelectedBlock(event.target.value);
                    fetchTowerNames(event.target.value);
                  }}
                >
                  {blockNames.map((block) => (
                    <MenuItem key={block.block_name} value={block.block_name}>
                      {block.block_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: '48%' }}>
                <InputLabel>Tower Name</InputLabel>
                <Select
                  value={selectedTower}
                  onChange={(event) => setSelectedTower(event.target.value)}
                >
                  {towerNames.map((tower) => (
                    <MenuItem key={tower.tower_name} value={tower.tower_name}>
                      {tower.tower_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="form_field" style={{ display: 'flex', justifyContent: 'space-between', marginLeft:'20px' }}>
              <TextField
                label="Level"
                type="number"
                value={level}
                onChange={(event) => setLevel(event.target.value)}
                onBlur={handleLevelBlur}
                error={Boolean(levelError)}
                helperText={levelError}
                style={{ width: '48%' }}
              />
              <TextField
                label="Lanes"
                type="number"
                value={lanes}
                onChange={(event) => {
                  const value = event.target.value;
                  setLanes(value);
                  setLaneSlots(Array.from({ length: value }, () => ''));
                }}
                style={{ width: '48%' }}
              />
            </div>
            {Array.from({ length: lanes }).map((_, index) => (
              <div key={index} className="form_field">
                <TextField
                  label={`Total Parking Slots for Lane ${index + 1}`}
                  type="number"
                  value={laneSlots[index]}
                  onChange={(event) => {
                    handleLaneSlotChange(event, index);
                    handleSlotsPerLaneChange(event, index);
                  }}
                  style={{ width: '48%' }}
                />
              </div>
            ))}

            {/* {Array.from({ length: lanes }).map((_, index) => (
              <div key={index} className="form_field">
                <TextField
                  label={`Total Parking Slots for Lane ${index + 1}`}
                  type="number"
                  value={laneSlots[index]}
                  onChange={(event) => handleLaneSlotChange(event, index)}
                  style={{ width: '400px' }}
                />
              </div>
            ))} */}
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '10px' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={generateSlotNumbers}
                style={{ width: '200px' }}
              >
                Generate Slot Numbers
              </Button>
            </div>
            {isGenerated && (
              <>
                <h3>Slot Numbers</h3>
                <Grid container spacing={1}>
                  <Grid item xs={11.5} style={{ paddingLeft: theme.spacing(10) }}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead className="tableHeadBold">
                          <TableRow>
                            <TableCell className="tb_cell">Slot Number</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="table_data">
                          {slots.map((slot, index) => (
                            <TableRow key={index} className="tb-row">
                              <TableCell className="row_cell">
                                <TextField
                                  value={slot}
                                  onChange={(event) => handleSlotChange(event, index)}
                                />
                              </TableCell>
                              <TableCell className="row_cell">
                                <IconButton onClick={() => handleDeleteSlot(index)}>
                                  <DeleteIcon className="delete-icon" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                {/* <div style={{ marginLeft: '35%', marginRight: '41%', marginTop: '20px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveSlots}
                    style={{ width: '200px', backgroundColor: 'purple', color: 'white', marginBottom: '20px' }}
                  >
                    Save Slots
                  </Button>
                </div> */}
              </>
            )}
          </div>
          <div className="form_actions" style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '10px' }}>
            <Button variant="contained" color="primary" type="submit" style={{ marginRight: '10px' }} >
              {editingIndex === -1 ? "Save" : "Update"}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReset}
              style={{ backgroundColor: "#f44336", color: "white" }}
            >
              Reset
            </Button>
          </div>
        </div>
      </form>
      <Grid container spacing={1}>
        <Grid item xs={12}>
        <div className="scrollable-container">
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="tableHeadBold">
                <TableRow>
                  <TableCell className="tb_cell">Block Name</TableCell>
                  <TableCell className="tb_cell">Tower Name</TableCell>
                  <TableCell className="tb_cell">Level</TableCell>
                  <TableCell className="tb_cell">Lanes</TableCell>
                  <TableCell className="tb_cell">Slots</TableCell>
                  <TableCell className="tb_cell">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table_data">
                {data &&  data.map((row, index) => (
                  <TableRow key={index} className="tb-row">
                    <TableCell className="row_cell">{row.block_name}</TableCell>
                    <TableCell className="row_cell">{row.tower_name}</TableCell>
                    <TableCell className="row_cell">{row.level}</TableCell>
                    <TableCell className="row_cell">{row.lanes}</TableCell>
                    {/* <TableCell className="row_cell">{row.slots.join(', ')}</TableCell> */}
                    <TableCell className="row_cell">{row.slots.map((slot) => Number(slot)).reduce((acc, val) => acc + val, 0)}</TableCell>

                    <TableCell className="row_cell">
                      <IconButton onClick={() => handleUpdate(index)}>
                        <EditIcon className="edit-icon" />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(index)}>
                        <DeleteIcon className="delete-icon" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ParkingSlotMaster;
