import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import ApiConfig from '../ApiConfig';
import './Chat.css';
// import { generateKeyPair, exportPublicKey, importPublicKey, encryptMessage, decryptMessage } from './utils/crypto';

const socket = io(`${ApiConfig.socketUrl2}`, {
  transports: ['websocket']
});

function Chat() {
  const [inputCode, setInputCode] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);
  const [senderId, setSenderId] = useState('');
  const [receiverId, setReceiverId] = useState('');
  const [showChatBox, setShowChatBox] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [keyPair, setKeyPair] = useState(null);
  // const [recipientPublicKey, setRecipientPublicKey] = useState(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (showChatBox) {
      setIsLoading(true);
      socket.emit('join', { senderId, receiverId });
      socket.emit('loadMessages', senderId);
      socket.on('previousMessages', (previousMessages) => {
        setMessages(previousMessages);
        setIsLoading(false);
      });
      // socket.on('newMessage', (message) => {
      //   setMessages((prevMessages) => [...prevMessages, message]);
      // });
      socket.on('newMessage', (message) => {
        setMessages((prevMessages) => [...prevMessages, {
          ...message,
          sender_id: parseInt(message.sender_id),
          receiver_id: parseInt(message.receiver_id)
        }]);
      });
      return () => {
        socket.off('previousMessages');
        socket.off('newMessage');
      };
    }
  }, [showChatBox, senderId, receiverId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const messageData = {
        senderId,
        receiverId,
        message: newMessage
      };
      socket.emit('message', messageData);
      // Optimistically add the message to the UI
      // setMessages((prevMessages) => [...prevMessages, {
      //   id: Date.now(), // temporary id
      //   sender_id: parseInt(senderId),
      //   receiver_id: parseInt(receiverId),
      //   message: newMessage,
      //   timestamp: new Date().toISOString(),
      // }]);
      setNewMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleStartChat = (e) => {
    e.preventDefault();
    if (senderId && receiverId) {
      setShowChatBox(true);
    }
  };

  const handleAccessSubmit = (e) => {
    e.preventDefault();
    if (inputCode === '124') {
      setAccessGranted(true);
    } else {
      alert('Hello World!!');
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., '12/31/2023'
    const formattedTime = date.toLocaleTimeString(); // e.g., '1:13:10 PM'
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="chat-container">
      {!accessGranted ? (
        <form onSubmit={handleAccessSubmit} className="access-form">
          <h1>Test</h1>
          <input
            type="text"
            value={inputCode}
            onChange={(e) => setInputCode(e.target.value)}
            placeholder="Enter code"
          />
          <button type="submit">Submit</button>
        </form>
      ) : (
        !showChatBox ? (
          <form onSubmit={handleStartChat} className="chat-form">
            <div className="input-group">
              <label>Sender ID: </label>
              <input
                type="text"
                value={senderId}
                onChange={(e) => setSenderId(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Receiver ID: </label>
              <input
                type="text"
                value={receiverId}
                onChange={(e) => setReceiverId(e.target.value)}
              />
            </div>
            <button type="submit" className="start-chat-btn">Start Chat</button>
          </form>
        ) : (
          <div className="chat-box">
            {isLoading ? (
              <div className="loading">Loading messages...</div>
            ) : (
              <div className="messages-container">
                {messages.map((msg) => (
                  <div
                    key={msg.id}
                    className={`message ${msg.sender_id === parseInt(senderId) ? 'sent' : 'received'}`}
                  >
                    <div>
                      <strong>{msg.sender_id === parseInt(senderId) ? 'You' : msg.sender_id}</strong>
                      <span style={{ marginLeft: '10px' }}>
                        {formatTimestamp(msg.timestamp)}
                      </span>
                    </div>
                    <div>{msg.message}</div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
            )}
            <div className="input-container">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type a message..."
                className="message-input"
              />
              <button onClick={handleSendMessage} className="send-btn">Send</button>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default Chat;