import axios from "axios";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import ApiConfig from "../ApiConfig";
import AttendentHeader from "./AttendentHeader";


const ParkingComponent = () => {
  const [vehicleType, setVehicleType] = useState("");
  const [totalSlots, setTotalSlots] = useState(0);
  const [availableSlots, setAvailableSlots] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const hourlyRate = 10;
  const [inCarNumber, setInCarNumber] = useState("");
  const [parkedCars, setParkedCars] = useState([]);
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))


  // To fetch data of total parking slots
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiConfig.baseUrl}parkingslots`);
        setTotalSlots(calculateTotalSlots(response.data));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // To get data of parked cars
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiConfig.baseUrl}parkedCars`);
        setParkedCars(response.data);
      } catch (error) {
        console.error(error);
        // setParkedCars([{id:'1', vehicleType:'Car', inCarNumber:'XC-VBN1-1234',inTime:'02:30 PM' },
        // {id:'2', vehicleType:'Scooter', inCarNumber:'XC-VBN1-1234',inTime:'02:30 PM' },
        // {id:'1', vehicleType:'Car', inCarNumber:'XC-VBN1-1234',inTime:'02:30 PM' },
        // {id:'2', vehicleType:'Scooter', inCarNumber:'XC-VBN1-1234',inTime:'02:30 PM' },
        // {id:'1', vehicleType:'Car', inCarNumber:'XC-VBN1-1234',inTime:'02:30 PM' },
        // {id:'2', vehicleType:'Scooter', inCarNumber:'XC-VBN1-1234',inTime:'02:30 PM' },
        // {id:'1', vehicleType:'Car', inCarNumber:'XC-VBN1-1234',inTime:'02:30 PM' },
        // {id:'2', vehicleType:'Scooter', inCarNumber:'XC-VBN1-1234',inTime:'02:30 PM' }]);
      }
    };
    fetchData();
  }, []);

  const calculateTotalSlots = (data) => {
    let total = 0;
    data.forEach((item) => {
      const slots = item.slots;
      total += slots.reduce((acc, slot) => acc + parseInt(slot), 0);
    });
    return total;
  };

  const handleIn = async (event) => {
    event.preventDefault();
    const currentTime = new Date().toLocaleString();
    const inTime = currentTime;
    const response = await axios.post(`${ApiConfig.baseUrl}vehicleIn/`, {
      vehicleType,
      inCarNumber,
      inTime,
    });
    setParkedCars([...parkedCars, response.data]);
    setAvailableSlots(availableSlots - 1);
    // reset form
    setInCarNumber("");
    setVehicleType("");
  };

  const handleOut = async (carId, inCarNumber, inTime) => {
    const currentTime = new Date().toLocaleString();
    const outTime = currentTime;
    const inTimeStamp = Date.parse(inTime);
    const outTimeStamp = Date.parse(outTime);
    const duration = Math.ceil((outTimeStamp - inTimeStamp) / 1000 / 60 / 60); // calculate duration in hours
    const BillAmount = duration * hourlyRate; // calculate cost based on hourly rate
    await axios.put(`${ApiConfig.baseUrl}vehicleOut/${carId}`, {
      inCarNumber,
      OutTime: outTime,
      duration,
      BillAmount,
    });
    setSearchValue("");
    // re-fetch data and update the grid
    const response = await axios.get(`${ApiConfig.baseUrl}parkedCars`);
    setParkedCars(response.data);

    // generate bill in pdf format
    const bill = {
      carNumber: inCarNumber,
      inTime: inTime,
      OutTime: outTime,
      duration: duration,
      cost: BillAmount,
    };

    // create a new pdf document
    const doc = new jsPDF();
    // add bill details to the pdf
    doc.text(`Car Number: ${bill.carNumber}`, 10, 10);
    doc.text(`In Time: ${bill.inTime}`, 10, 20);
    doc.text(`Out Time: ${bill.OutTime}`, 10, 30);
    doc.text(`Duration: ${bill.duration} hours`, 10, 40);
    doc.text(`Cost: Rs.${bill.cost}`, 10, 50);
    // download the pdf
    doc.save(`bill_${bill.carNumber}.pdf`);

    alert("Car out successfully! Your bill has been generated.");
  };

  const handleVehicleTypeChange = (event) => {
    setVehicleType(event.target.value);
  };

  const inTimeButNoOutTime = parkedCars.filter(
    (car) => car.inTime && !car.OutTime
  );
  const parkedCarsCount = inTimeButNoOutTime.length;

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}


  return (
    <div>
     <AttendentHeader/>
    <div className="row">
      <div className="form_head mt-2 d-flex" style={{    width: '92%', margin: '0px 4%'}}>
        <div className="float-left col-md-2 mt-1" style={{fontSize:'22px', color:'#010152'}}>Parking Details</div>

       <div className="col-md-10 d-flex float-right" style={{fontSize:'12px',fontSize: '12px', position: 'static',    justifyContent: 'end'}}>
          <p className="mr-3 mt-2" style={{fontSize:'13px'}}>Total Slots: {totalSlots}</p>
          <p className="mr-3 mt-2" style={{fontSize:'13px'}}>Available Slots: {totalSlots - parkedCarsCount} </p>
          <div>
            {/* <TextField   label="Search"    /> */}
            <input type="text" className="form-control" onChange={(e) => setSearchValue(e.target.value)} placeholder="Search"/>
          </div>
       </div>
      </div>
      <div className="col-md-12 mt-2">
      <form onSubmit={handleIn}>
      <div  className="SlotMas_form pb-1">     
         <div className="form_body d-flex">
          <div className="d-flex">
          <label className="form-label mr-2 mt-2" id="vehicle-type">Vehicle Type</label>
      
        {/* <Select className="mr-2 "
          labelId="vehicle-type"
          id="id-vehicle-id"
          value={vehicleType}
          label="Vehicle Type"
          onChange={handleVehicleTypeChange}
        >
          <MenuItem value="car">Car</MenuItem>
          <MenuItem value="scooter">Scooter</MenuItem>
          <MenuItem value="truck">Truck</MenuItem>
        </Select> */}
        <select className="form-control mr-4 " labelId="vehicle-type"
          id="id-vehicle-id" value={vehicleType}  label="Vehicle Type"  onChange={handleVehicleTypeChange}   >
          <option value="car">Car</option>
          <option value="scooter">Scooter</option>
          <option value="truck">Truck</option>
        </select> 
          </div>
          <div className=" mr-4">
          <input className="form-control" style={{padding:'5px !important'}}
          placeholder="Vehicle Number"
          value={inCarNumber}
          onChange={(event) => setInCarNumber(event.target.value)}     />
          </div> 
          <div  style={{textAlign:'center'}}> <button  className="btn btn_Primar pr-2 pl-2" type="submit">Park In</button></div>
      
        </div>
      </div>     
      </form>   
      </div>
      {/* <div className="col-md-4"></div> */}
     
      

      

      {parkedCars.length > 0 && (

        <table id="parking" style={{margin:'10px 5%',width:'90%'}}>        
        <tr>
          <th className="tb_cell">S. No.</th>
          <th className="tb_cell">Vehicle</th>
          <th className="tb_cell"> Number</th>
          <th className="tb_cell">In Time</th>
          <th className="tb_cell">Action</th>
        </tr>       
        <tbody >
        { parkedCars.filter(
                  (car) =>
                    car &&
                    car.inCarNumber && !car.OutTime &&
                    car.inCarNumber.includes(searchValue)
                ).map((car,index)=>{
        return (
          <tr className="tb-row" key={index}>
             <td className="row_cell">{index + 1}</td>
             <td className="row_cell">{car.vehicleType}</td>
             <td className="row_cell">{car.inCarNumber}</td>
             <td className="row_cell">{car.inTime}</td>
             <td className="row_cell">
             <button className="btn btn-warning" onClick={() => handleOut(car.id, car.inCarNumber, car.inTime)}  >
                 Park Out
            </button>
             </td>
          </tr>
        )
        })}
        
        </tbody>
        </table>

     
      )}
    </div>
    </div>
  );
};
export default ParkingComponent;
