import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import ApiConfig from "../ApiConfig";
import Header from '../header';
import './../Society_Portal/table.css';


function AdminList() {

  const [isEdit, EditRecord] = useState(false);
  const [oper, setOper] = useState(-1);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userType, setUserType] = useState('1');
  const [parkingType, setParkingType] = useState('');
  const [userContact, setUserContact] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setParkUpdateError] = useState('');
  const [data, setData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiConfig.baseUrl}api/users`);
        setData(Array.isArray(response.data) ? response.data : [response.data]);

      } catch (error) {
        console.error(error);
        //       setData([{slotNumber:'1',vehicleNumber:'1', flatNumber:'123',ownerName:'ABC' , ownerContact:"123456789"},
        // {slotNumber:'1',vehicleNumber:'1', flatNumber:'123',ownerName:'ABC' , ownerContact:"123456789"},
        // {slotNumber:'1',vehicleNumber:'1', flatNumber:'123',ownerName:'ABC' , ownerContact:"123456789"}]);

      }
    };
    fetchData();
  }, []);

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    if (userName == '' || userPassword == '' || userType == '' || parkingType == '' || errorMsg.type) {
      setHasError(true)
    }
    else {
      try {
        await axios.post(
          `${ApiConfig.baseUrl}api/users/register`,
          { userName, userPassword, userType, userContact, userEmail, parkingType }
        );
        //fetchData();  // throwing an error
        EditRecord(false)
        // const updatedData = [...data];
        // updatedData[editingIndex] = {userName, userPassword, userType,userContact,userEmail, parkingType};
        // setData(updatedData);
        alert("Record added sucessfully !");
        setEditingIndex(-1);
      } catch (error) {
        console.error(error);
      }
    }
  }
  const handleUpdate = (item, index) => {
    setUserName(item.userName);
    setUserPassword(item.userPassword);
    setUserType(item.userType);
    setParkingType(item.parkingType);
    EditRecord(true);
    setEditingIndex(index);
  }
  const handleDelete = async (index) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this ${data[index].vehicleNumber}?`
    );
    if (confirmDelete) {
      try {
        const id = data[index].id;
        await axios.delete(`${ApiConfig.baseUrl}users/register`);
        const updatedData = [...data];
        updatedData.splice(index, 1);
        setData(updatedData);
        alert("Record deleted successfully!");
      } catch (error) {
        console.error(error);
      }
    }
  };


  const cancelUpdate = () => {
    setUserName('');
    setUserPassword('');
    setUserType('');
    setParkingType('');
    EditRecord(false);
    setEditingIndex(-1);
  }
  const renderErrorMessage = (type) =>
    type === errorMsg.type && (
      <div className="error">{errorMsg.msg}</div>
    );

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
  }

  return (
    <div>
      <Header />
      <div className='row'>

        {isEdit == false && (
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div style={{ backgroundColor: '#e3930b', height: '60px', color: 'white', padding: '12px 10px', margin: '10px' }}>
              <div style={{ float: 'left', fontWeight: '600', fontSize: '24px' }}>Admin Details</div>
              <div style={{ float: 'right' }}><button onClick={() => { setOper('A'); EditRecord(true); }} className='btn_Primar'>Add Admin </button></div>
            </div>
            <table id="parking" style={{ margin: '10px 10%', width: '79%' }}>
              <tr>
                <th className="tb_cell">Admin Name</th>
                <th className="tb_cell">Password</th>
                <th className="tb_cell">userType</th>
                <th className="tb_cell">Parking Type</th>
                <th className="tb_cell"></th>
              </tr>
              <tbody >
                {data.filter(item => item.userType == 1).map((item, index) => {
                  return (
                    <tr className="tb-row" key={item.id}>
                      <td className="row_cell">{item.userName}</td>
                      <td className="row_cell">{item.userPassword}</td>
                      <td className="row_cell">{item.userType}</td>
                      <td className="row_cell">{item.parkingType}</td>
                      <td className="row_cell">
                        <IconButton onClick={() => { handleUpdate(item, index); setOper('E') }}>
                          <EditIcon className="edit-icon" />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(index)}>
                          <DeleteIcon className="delete-icon" />
                        </IconButton>
                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>
          </div>
        )}

        {isEdit && (
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='row'>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <div style={{ backgroundColor: '#e3930b', height: '50px', color: 'white', padding: '10px', margin: '10px' }}>
                  <div style={{ float: 'left', fontWeight: '600', fontSize: '18px' }}>Admin Details</div>
                  <div style={{ float: 'right' }}><button className='btn_Primar' onClick={() => { setOper('A'); EditRecord(true); cancelUpdate() }}>Add Admin</button></div>
                </div>
                <table id="parking" style={{ margin: '10px 10%', width: '79%' }}>
                  <tr>
                    <th className="tb_cell">Admin Name</th>
                    <th className="tb_cell">Password</th>
                    <th className="tb_cell">userType</th>
                    <th className="tb_cell">Parking Type</th>
                    <th className="tb_cell"></th>
                  </tr>
                  <tbody >
                    {data.filter(item => item.userType == 1).map((item, index) => {
                      return (
                        <tr className="tb-row" key={item.id}>
                          <td className="row_cell">{item.userName}</td>
                          <td className="row_cell">{item.userPassword}</td>
                          <td className="row_cell">{item.userType}</td>
                          <td className="row_cell">{item.parkingType}</td>
                          <td className="row_cell">
                            <IconButton onClick={() => handleUpdate(item, index)}>
                              <EditIcon className="edit-icon" />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(index)}>
                              <DeleteIcon className="delete-icon" />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              </div>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <form >
                  <div className="SlotMas_form">
                    <div className="form_head" > Admin Registration Form </div>
                    {hasError ? <div style={{ color: 'red', textAlign: 'center' }}>Please enter required field (*)</div> : ''}
                    <div className="form_body">
                      <div >
                        <label className="form-label mb-3">Admin Name <span style={{ color: 'red' }}>*</span>:
                          <input type="text" className="form-control" value={userName}
                            onChange={(event) => setUserName(event.target.value)} required />
                        </label>

                      </div>
                      <div >
                        <label className="form-label mb-3"> Password<span style={{ color: 'red' }}>*</span>:
                          <input type="text" className="form-control" value={userPassword}
                            onChange={(event) => setUserPassword(event.target.value)} required />
                        </label>

                      </div>

                      <div >
                        <label className="form-label mb-3">User Type<span style={{ color: 'red' }}>*</span>:
                          <input type="text" className="form-control" value='Admin' required />
                        </label>
                      </div>

                      <div >
                        <label className="form-label mb-3">Parking Type<span style={{ color: 'red' }}>*</span>:
                          <select className="form-control" value={parkingType} onChange={(event) => setParkingType(event.target.value)} required>
                            <option value='1'>Public</option>
                            <option value='2'>Societal</option>
                          </select>
                          {/* <input type="text" className="form-control"  value={ownerName} onChange={(event) => setOwnerName(event.target.value)} required/> */}
                        </label>
                      </div>

                      <div >
                        <label className="form-label mb-3">
                          Contact Number <span style={{ color: 'red' }}>*</span>:
                          <input type="text" className="form-control" value={userContact} onChange={(event) => setUserContact(event.target.value)} required />
                        </label>
                      </div>

                      <div >
                        <label className="form-label mb-3">
                          Email <span style={{ color: 'red' }}>*</span>:
                          <input type="email" className="form-control" value={userEmail} onChange={(event) => setUserEmail(event.target.value)} required />
                        </label>
                      </div>

                      <div >
                        <Link to="/parking-details">
                          <button style={{ float: 'left' }} variant="contained" onClick={cancelUpdate} className="btn btn-danger" >Cancel</button>
                        </Link>
                        <button style={{ float: 'right' }} variant="contained" className="btn btn-success" onClick={handleUpdateSubmit}  >
                          Add
                        </button>



                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>


        )}

      </div>
    </div>

    // 
  )
}

export default AdminList