import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import io from 'socket.io-client';
import ApiConfig from '../ApiConfig';



const socket = io(`${ApiConfig.socketUrl1}`, { transports: ['websocket'] });

const VisitorEntry = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [userID, setUserID] = useState('');
  const [permits, setPermits] = useState({});
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))


  useEffect(() => {
    socket.on('receive_message', (e) => {
      console.log("error, ",e);
      try {
        const data = JSON.parse(e);
        setChatMessages(Array.isArray(data) ? data : []);
        console.log('message received', data);
      } catch (error) {
        console.error('Failed to parse received message:', error);
      }
    });

    axios.get(`${ApiConfig.baseUrl}api/visitor/get-last-permission`)
      .then(res => {
        setChatMessages(Array.isArray(res.data) ? res.data : [res.data]);
      })
      .catch(err => console.error('Failed to fetch last permission:', err));

    axios.get(`${ApiConfig.baseUrl}api/visitor/get-all-permission`)
      .then(res => {
        const permitData = {};
        res.data.forEach(p => {
          permitData[p.id] = p.permit;
        });
        setPermits(permitData);
      })
      .catch(err => console.error('Failed to fetch all permissions:', err));

    return () => {
      socket.off('receive_message');
    };
  }, []);

  const handleUserID = (event) => {
    setUserID(event.target.value);
  }

  const handleFilter = () => {
    if (!userID) return chatMessages;
    return chatMessages.filter(message => message.flatUserId === userID).map(message => {
      if (message.id in permits) {
        message.permit = permits[message.id];
      }
      return message;
    });
  };

  const handlePermit = async (permissionId, permit) => {
    try {
      const res = await axios.put(`${ApiConfig.baseUrl}update-permission/${permissionId}`, { permit });
      console.log(res.data);
      setPermits(prevPermits => ({ ...prevPermits, [permissionId]: permit }));

      socket.emit('permission_response', {
        id: permissionId,
        permit: permit === 1 ? 1 : 0
      });

    } catch (err) {
      console.error(err);
    }
  };

  const filteredMessages = handleFilter();

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}

  return (
    <div>
      <h2>Visitor Entry Permission</h2>
      <input type="text" placeholder="Enter userID" onChange={handleUserID} />
      <button onClick={handleFilter}>Filter</button>
      {filteredMessages.length > 0 ? (
        <ul>
          {filteredMessages.map((m, index) => (
            <li key={index}>
              {m.visitorName} visited on {new Date(m.time).toLocaleString()} - {permits[m.id] === undefined ? (
                <>
                  <button onClick={() => handlePermit(m.id, "true")}>Accept</button>
                  <button onClick={() => handlePermit(m.id, "false")}>Reject</button>
                </>
              ) : permits[m.id] === "true" ? 'Accepted' : permits[m.id] === "false" ? 'Rejected' : 'Pending'}
            </li>
          ))}
        </ul>
      ) : (
        <p>No request found</p>
      )}

      <table id="parking" style={{ margin: '10px 6%', width: '88%' }}>
        <thead>
          <tr>
            <th className="tb_cell">Name</th>
            <th className="tb_cell">Contact</th>
            <th className="tb_cell">Date</th>
            <th className="tb_cell">Visit Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredMessages.length > 0 ? (filteredMessages.map((item, index) => (
            <tr className="tb-row" key={index}>
              <td className="row_cell">{item.visitorName}</td>
              <td className="row_cell">{item.visitorVehicle}</td>
              <td className="row_cell">{new Date(item.time).toLocaleString()}</td>
              <td className="row_cell">
                {permits[item.id] === null ? (
                  <>
                    <button className="btn btn-success" onClick={() => handlePermit(item.id, 1)}>Accept</button>
                    <button className="btn btn-danger" onClick={() => handlePermit(item.id, 0)}>Reject</button>
                  </>
                ) : permits[item.id] === 1 ? 'Accepted' : permits[item.id] === "0" ? 'Rejected' : 'Pending'}
              </td>
            </tr>
          ))) : (
            <tr>
              <td colSpan="4"><p>No request found</p></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VisitorEntry;
