import axios from 'axios';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import io from "socket.io-client";
import ApiConfig from '../ApiConfig';



const socket = io(`${ApiConfig.socketUrl1}`, { transports: ['websocket'] });


const isLogged = Boolean(localStorage.getItem("isLoggedIn"))



const NotificationPage = () => {
  const [message, setMessage] = useState('');
  //const [chatMessages, setChatMessages] = useState([]);
  const [carRegNo, setCarRegNo] = useState('');
  const [slotNo, setSlotNo] = useState('');
  const [notification, setNotification] = useState({});

  function handleNotification(response) {
    handleEmitData()
    setNotification(response.data);
  }

  function handleEmitData() {
    axios.get(`${ApiConfig.baseUrl}messages`)
      .then(response => {
        const data = response.data;
        setMessage(data);
        // to send data to socket server
        socket.emit("send_message", JSON.stringify(data));
        //console.log('message sending to server',data);
      })
      .catch(error => {
        console.log(error);
        alert('Error retrieving messages');
      });
  }


  const handleChange = (e) => {
    //setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //to send data to the database
    axios.post(`${ApiConfig.baseUrl}wrongparking`, { carRegNo, slotNo })
      .then(handleNotification)
      .catch(error => {
        console.log(error);
        //to clear the textboxes
        setCarRegNo("");
        setSlotNo("");
      });
  };

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Car Number:
          <input type="text" value={carRegNo} onChange={e => setCarRegNo(e.target.value)} />
        </label>
        <br />
        <label>
          Slot Number:
          <input type="text" value={slotNo} onChange={e => setSlotNo(e.target.value)} />
        </label>
        <br />
        <button type="submit">Send</button>
      </form>

      {/* {notification.userContactInfo ? (
        <p>Notification sent to: {notification.userContactInfo.ownerContact}</p> // show this to sender in toast message
      ) : (
        <p>Data not found, This vehicle may belong to some guest.</p>
      )} */}
      {notification.length ? (
        <p>Message: {notification.message}</p> // to receiver in inbox
      ) : null}
    </div>
  );
};

export default NotificationPage;
