import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { createTheme } from '@mui/material/styles';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import ApiConfig from "../ApiConfig";

import SocietyHeader from './SocietyHeader';

const theme = createTheme({
  spacing: 8,
});

const ViewFlats = () => {
  const [data, setData] = useState([]);
  const [currentView, setCurrentView] = useState('blocks');
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedTower, setSelectedTower] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editedFlatNumber, setEditedFlatNumber] = useState('');
  
  const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
  const [selectedFlat, setSelectedFlat] = useState(null);
  const [isMaidAllowed, setIsMaidAllowed] = useState(true);
  const [isDeliveryAllowed, setIsDeliveryAllowed] = useState(true);
  const [isCabAllowed, setIsCabAllowed] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${ApiConfig.baseUrl}api/societyManagement/getAllDataHeirarchy`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Failed to fetch data. Please try again.');
    }
  };

  const handleEdit = (id, flatNumber) => {
    setEditingId(id);
    setEditedFlatNumber(flatNumber);
  };

  const handleSave = async (id) => {
    try {
      await axios.put(`${ApiConfig.baseUrl}api/societyManagement/updateFlat/${id}`, {
        flatNumber: editedFlatNumber
      });
      setEditingId(null);
      fetchData(); // Refresh the list
    } catch (error) {
      console.error('Error updating flat:', error);
      alert('Failed to update flat. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this flat?')) {
      try {
        await axios.delete(`${ApiConfig.baseUrl}api/societyManagement/deleteFlat/${id}`);
        fetchData(); // Refresh the list
      } catch (error) {
        console.error('Error deleting flat:', error);
        alert('Failed to delete flat. Please try again.');
      }
    }
  };

  const handleBlockClick = (block) => {
    setSelectedBlock(block);
    setCurrentView('towers');
  };

  const handleTowerClick = (tower) => {
    setSelectedTower(tower);
    setCurrentView('floors');
  };

  const handleFloorClick = (floor) => {
    setSelectedFloor(floor);
    setCurrentView('flats');
  };

  const handleBack = () => {
    if (currentView === 'towers') {
      setCurrentView('blocks');
      setSelectedBlock(null);
    } else if (currentView === 'floors') {
      setCurrentView('towers');
      setSelectedTower(null);
    } else if (currentView === 'flats') {
      setCurrentView('floors');
      setSelectedFloor(null);
    }
  };

  const handleSavePermissions = async () => {
    try {
      await axios.post(`${ApiConfig.baseUrl}/savePermissions`, {
        flatId: selectedFlat.id,
        isMaidAllowed,
        isDeliveryAllowed,
        isCabAllowed,
      });
      console.log('Permissions saved successfully!');
      setPermissionsDialogOpen(false);
    } catch (error) {
      console.error('Error saving permissions:', error);
      alert('Failed to save permissions. Please try again.');
    }
  };

  const renderTable = () => {
    switch (currentView) {
      case 'blocks':
        return (
          <>
            <h2>Block Names</h2>
            <div className="table-wrapper">
            <Table>
              <TableHead className="tableHeadBold">
                <TableRow>
                  <TableCell className="tb_cell">Block Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table_data">
                {data.map((block) => (
                  <TableRow key={block.blockName} className="tb-row" onClick={() => handleBlockClick(block)}>
                    <TableCell className="row_cell">{block.blockName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </div>
          </>
        );
      case 'towers':
        return (
          <>
            <h2>{selectedBlock.blockName} - Tower Names</h2>
            <Table>
              <TableHead className="tableHeadBold">
                <TableRow>
                  <TableCell className="tb_cell">Tower Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table_data">
                {selectedBlock.towers.map((tower) => (
                  <TableRow key={tower.towerName} className="tb-row" onClick={() => handleTowerClick(tower)}>
                    <TableCell className="row_cell">{tower.towerName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        );
      case 'floors':
        return (
          <>
            <h2>{selectedBlock.blockName} - {selectedTower.towerName} - Floor Numbers</h2>
            <Table>
              <TableHead className="tableHeadBold">
                <TableRow>
                  <TableCell className="tb_cell">Floor Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table_data">
                {selectedTower.floors.map((floor) => (
                  <TableRow key={floor.floorNumber} className="tb-row" onClick={() => handleFloorClick(floor)}>
                    <TableCell className="row_cell">{floor.floorNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        );
      case 'flats':
        return (
          <>
            <h2>{selectedBlock.blockName} - {selectedTower.towerName} - {selectedFloor.floorNumber} - Flats</h2>
            <Table>
              <TableHead className="tableHeadBold">
                <TableRow>
                  <TableCell className="tb_cell">Flat Number</TableCell>
                  <TableCell className="tb_cell">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table_data">
                {selectedFloor.flats.map((flat) => (
                  <TableRow key={flat.id} className="tb-row">
                    <TableCell className="row_cell">
                      {editingId === flat.id ? (
                        <TextField
                          value={editedFlatNumber}
                          onChange={(e) => setEditedFlatNumber(e.target.value)}
                        />
                      ) : (
                        flat.flatNumber
                      )}
                    </TableCell>
                    <TableCell className="row_cell">
                      {editingId === flat.id ? (
                        <Button onClick={() => handleSave(flat.id)}>Save</Button>
                      ) : (
                        <IconButton onClick={() => handleEdit(flat.id, flat.flatNumber)}>
                          <EditIcon className="edit-icon" />
                        </IconButton>
                      )}
                      <IconButton onClick={() => handleDelete(flat.id)}>
                        <DeleteIcon className="delete-icon" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        );
      default:
        return null;
    }
  };


  return (
    <div>
      <SocietyHeader />
      <div className="SlotMas_form">
        <div className="form_head">
          <div>View Flats</div>
          {/* <div className="add-flats-link">
            <Link to="/add-flat">Add Flats</Link>
          </div> */}
        </div>
        
        <div className="form_body">
          {currentView !== 'blocks' && (
            <Button startIcon={<ArrowBackIcon />} onClick={handleBack}>
              Back
            </Button>
          )}
          <Grid container spacing={1}>
            <Grid item xs={11.5} style={{ paddingLeft: theme.spacing(10) }}>
              <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }} >
                {renderTable()}
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog open={permissionsDialogOpen} onClose={() => setPermissionsDialogOpen(false)}>
        <DialogTitle>Edit Permissions</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={<Checkbox checked={isMaidAllowed} onChange={(e) => setIsMaidAllowed(e.target.checked)} />}
            label="Is Maid Allowed"
          />
          <FormControlLabel
            control={<Checkbox checked={isDeliveryAllowed} onChange={(e) => setIsDeliveryAllowed(e.target.checked)} />}
            label="Is Delivery Allowed"
          />
          <FormControlLabel
            control={<Checkbox checked={isCabAllowed} onChange={(e) => setIsCabAllowed(e.target.checked)} />}
            label="Is Cab Allowed"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPermissionsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSavePermissions} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewFlats;
