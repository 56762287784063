import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import logo from './../images/logo.png';

const Header = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  // const downloadFile = (url) => {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.target = '_blank';
  //   link.download = '';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);

  //   link.onerror = () => {
  //     console.error(`Failed to download file from ${url}`);
  //   };
  // };

  // const handleDownloadUserApp = () => {
  //   const userAppUrl = '/MoblieApkLink/user/app-release.apk';
  //   downloadFile(userAppUrl);
  // };

  // const handleDownloadGuardApp = () => {
  //   const guardAppUrl = '/MoblieApkLink/guard/app-release.apk';
  //   downloadFile(guardAppUrl);
  // };

  return (
    <div
      className='row'
      style={{
        backgroundColor: 'rgb(1, 1, 82)',
        height: '75px',
        padding: '6px 16px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={logo}
          alt='Logo'
          style={{ height: '50px', width: '50px', borderRadius: '50%' }}
        />
        <div style={{ fontWeight: '600', fontSize: '13px', marginLeft: '10px' }}>
          PARK 360
        </div>
      </div>
      <div>
        {/* <button
          onClick={handleDownloadUserApp}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: '600',
            marginRight: '10px',
          }}
        >
          Download User App
        </button>
        <button
          onClick={handleDownloadGuardApp}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: '600',
          }}
        >
          Download Guard App
        </button> */}
      </div>
    </div>
  );
};

export default Header;
