import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import ApiConfig from '../ApiConfig';


const AddUser = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState(0);
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState('');

 
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))


  // useEffect(() => {
  //   const storedLoggedInId = localStorage.getItem('loggedInId');
  //   const storedSocietyId = localStorage.getItem('societyId');
  //   const storedUserType = localStorage.getItem('userType');

  //   if (storedLoggedInId) setLoggedInId(storedLoggedInId);
  //   if (storedSocietyId) setSocietyId(storedSocietyId);
  //   if (storedUserType) setLoggedInUserType(storedUserType);
  // }, []);


  const handleAddUser = async (event) => {
    event.preventDefault();
    const parkingType = 2; // Always set parkingType to 2
    if (userName === '' || password === '' || userType === 0 || contactNumber === '' || email === '') {
        // setHasError(true);
    } else {
        const userPassword = password;
        const userContact = contactNumber;
        const userEmail = email;
        try {
            await axios.post(
                `${ApiConfig.baseUrl}api/users/register`,
                { userName, userPassword, userType, userContact, userEmail, parkingType }
            );
            
            alert("Record added successfully!");
        } catch (error) {
            console.error(error);
        }
    }
};

const cancelAddUser = () => {
    setUserName('');
    setPassword('');
    setUserType('0');
    setContactNumber('');
    setEmail('');
};

if (!isLogged) {
  return <Navigate to={"/"} replace={true} />
}

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="User Name"
            required
            fullWidth
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            type="password"
            required
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="User Type"
            required
            fullWidth
            select
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            variant="outlined"
            margin="normal"
          >
            <MenuItem value={1}>Admin</MenuItem>
            <MenuItem value={2}>User</MenuItem>
            <MenuItem value={3}>Gate Keeper</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Contact Number"
            required
            fullWidth
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            required
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button onClick={handleAddUser} color="primary" variant="contained">
            Add
          </Button>
          <Button onClick={cancelAddUser} color="secondary" variant="contained" style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUser;
