import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import io from "socket.io-client";
import ApiConfig from '../ApiConfig';
import logo from './../../images/logo.png';
import './customerInfo.css';

const socket = io(`${ApiConfig.socketUrl1}`, { transports: ['websocket'] });


const CustomerInformation = () => {
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState({});
  const [showNotifications, setShowNotifications] = useState(false);
  const [showVisitor, setShowVisitor] = useState(false);
  const [showWrongParkingForm, setShowWrongParkingForm] = useState(false);
  const [custInfo, setCustInfo] = useState({});
  const [carRegNo, setCarRegNo] = useState('');
  const [slotNo, setSlotNo] = useState('');
  const [notifications, setNotifications] = useState([]);
  const isLoggedIn = Boolean(localStorage.getItem("isLoggedIn"));
  const userId = location.state;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${ApiConfig.baseUrl}api/societyManagement/details/allusers`);
        const userData = response.data.find(user => user.user.id === userId);
        setCustInfo(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
    fetchData();
  }, [userId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMessages();
    }, 300000000);
    return () => clearInterval(interval);
  }, []);

  function fetchMessages() {
    axios.get(`${ApiConfig.baseUrl}messages`, { params: { id: custInfo?.user?.id } })
      .then(response => {
        const data = response.data;
        const sortedData = data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setNotifications(sortedData);
        socket.emit("send_message", JSON.stringify(data));
      })
      .catch(error => {
        console.error('Error fetching messages:', error);
        alert('Error retrieving messages');
      });
  }

  function handleWrongParkSubmit(event) {
    event.preventDefault();

    axios.post(`${ApiConfig.baseUrl}wrongparking`, { carRegNo, slotNo })
      .then(response => {
        fetchMessages();
        setShowWrongParkingForm(false);
        setCarRegNo('');
        setSlotNo('');
      })
      .catch(error => {
        console.error('Error reporting wrong parking:', error);
        alert('Error reporting wrong parking');
      });
  }

  async function handleSlotNumBlur(event) {
    const { value } = event.target;
    try {
      const response = await axios.get(`${ApiConfig.baseUrl}parkingAllotmentSlot/${value}`);
      if (!response.data) {
        setErrorMsg({ type: 'slotNum', msg: `Slot Number ${value} does not exist. Please enter a valid Slot Number.` });
      } else {
        setErrorMsg({});
      }
    } catch (error) {
      console.error('Error checking slot number:', error);
      setErrorMsg({ type: 'slotNum', msg: `Error checking slot number: ${error.message}` });
    }
  }
  
  function handlePermit(answer) {
    console.log(answer);
  }

  if (!isLoggedIn) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div>
      <div className='row' style={{ backgroundColor: 'rgb(1, 1, 82)', height: '75px', padding: '6px 16px', color: 'white' }}>
        <div className='col-md-10'>
          <img src={logo} style={{ height: '50px', width: '50px', borderRadius: '50%' }} alt="logo" />
          <div style={{ fontWeight: '600', fontSize: '13px', lineHeight: '50px' }}>PARKING</div>
        </div>
        <div className='col-md-2 d-flex align-items-center justify-content-end'>
          <div className="float-right">
            <div className="not-count">{notifications.length}</div>
            <IconButton onClick={() => setShowNotifications(!showNotifications)} style={{ outline: 'none' }}>
              <NotificationsActiveIcon className="bellIcon" />
            </IconButton>
            {showNotifications && (
              <div className="notDropDown">
                <div className="Not-head">Notifications</div>
                <div className="msgList">
                  {notifications.length ? (
                    notifications.map(item => (
                      <div className="msg" key={item.id}>
                        <p>From: {item.from}</p>
                        <p>Date: {item.date}</p>
                        <p>Message: {item.message}</p>
                      </div>
                    ))
                  ) : <p>No Notifications.</p>}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="SlotMas_form">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="form_head">User Information</div>
            <ol className="name-value">
              <li>
                <label>User Name:</label>
                <span>{custInfo?.user?.userName || 'N/A'}</span>
              </li>
              <li>
                <label>Contact Number:</label>
                <span>{custInfo?.user?.userContact || 'N/A'}</span>
              </li>
              <li>
                <label>Email:</label>
                <span>{custInfo?.user?.userEmail || 'N/A'}</span>
              </li>
            </ol>
            {custInfo?.flats?.length > 1 ? (
              custInfo?.flats?.map((flatInfo, index) => (
                <div key={flatInfo.flat.id}>
                  <div className="form_head">Allotted Flat And Slot {index + 1}</div>
                  <ol className="name-value">
                    <li>
                      <label>Block Name:</label>
                      <span>{flatInfo.flat.block_name || 'N/A'}</span>
                    </li>
                    <li>
                      <label>Tower Name:</label>
                      <span>{flatInfo.flat.tower_name || 'N/A'}</span>
                    </li>
                    <li>
                      <label>Floor Number:</label>
                      <span>{flatInfo.flat.floor_number || 'N/A'}</span>
                    </li>
                    <li>
                      <label>Flat Number:</label>
                      <span>{flatInfo.flat.flat_number || 'N/A'}</span> <span>{custInfo?.flats?.[0]?.parkingSlots?.length ? custInfo.flats[0].parkingSlots.map(slot => slot.slot_info || 'N/A').join(', ') : 'N/A'}</span>
                    </li>
                    <li>
                      <label>Slot Info:</label>
                      {/* <span>{flatInfo.parkingSlots.map(slot => slot.slot_info || 'N/A').join(', ')}</span> */}
                      <span>{custInfo?.flats?.[0]?.parkingSlots?.length ? custInfo.flats[0].parkingSlots.map(slot => slot.slot_info || 'N/A').join(', ') : 'N/A'}</span>
                    </li>
                  </ol>
                </div>
              ))
            ) : (
              <div>
                <div className="form_head">Allotted Flat And Slot</div>
                <ol className="name-value">
                  <li>
                    <label>Block Name:</label>
                    <span>{custInfo?.flats?.[0]?.flat.block_name || 'N/A'}</span>
                  </li>
                  <li>
                    <label>Tower Name:</label>
                    <span>{custInfo?.flats?.[0]?.flat.tower_name|| 'N/A'}</span>
                  </li>
                  <li>
                    <label>Floor Number:</label>
                    <span>{custInfo?.flats?.[0]?.flat.floor_number || 'N/A'}</span>
                  </li>
                  <li>
                    <label>Flat Number:</label>
                    <span>{custInfo?.flats?.[0]?.flat.flat_number || 'N/A'}</span>
                  </li>
                  <li>
                    <label>Slot Info:</label>
                    {/* <span>{custInfo?.flats?.[0]?.parkingSlots.map(slot => slot.slot_info || 'N/A').join(', '): 'N/'}</span>
                     */}
                    <span>{custInfo?.flats?.[0]?.parkingSlots?.length ? custInfo.flats[0].parkingSlots.map(slot => slot.slot_info || 'N/A').join(', ') : 'N/A'}</span>

                  </li>
                </ol>
              </div>
            )}
            <div className="row mt-3">
              <div className="col-md-12">
                <button className="btn_Primar float-right" onClick={() => setShowWrongParkingForm(true)}>Report Wrong Parking</button>
              </div>
            </div>
            {showWrongParkingForm && (
              <div className="row mt-3">
                <div className="col-md-12">
                  <form onSubmit={handleWrongParkSubmit}>
                    <div className="form_head">Enter Wrong Parked Vehicle Information</div>
                    <div className="form_body">
                      <div className="form-group">
                        <label>Car Registration Number <span style={{ color: 'red' }}>*</span>:</label>
                        <input type="text" className="form-control" value={carRegNo} onChange={e => setCarRegNo(e.target.value)} required />
                      </div>
                      <div className="form-group">
                        <label>Slot Number <span style={{ color: 'red' }}>*</span>:</label>
                        <input type="text" className="form-control" name="slotNo" value={slotNo} onChange={e => setSlotNo(e.target.value)} onBlur={handleSlotNumBlur} required />
                        {errorMsg.type === 'slotNum' && (
                          <div className="error">{errorMsg.msg}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn btn-success float-right">Report</button>
                        <button type="button" className="btn btn-warning float-left" onClick={() => setShowWrongParkingForm(false)}>Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
      {showVisitor && (
        <div className="out-cont">
          <div className="container">
            <div className="confirmation-text">
              visitorName wants to visit you. His contact number is 12345
            </div>
            <div className="button-container">
              <button className="btn btn-success" onClick={() => handlePermit(true)}>Accept</button>
              <button className="btn btn-danger" onClick={() => handlePermit(false)}>Reject</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerInformation;
