import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import io from 'socket.io-client';
import ApiConfig from '../ApiConfig';


const socket = io(`${ApiConfig.socketUrl1}`, { transports: ['websocket'] });


const InboxPage = () => {

 // const [messages, setMessages] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [userID, setUserID] = useState('');
  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))



  useEffect(() => {
    socket.on('receive_message', (e) => {
      //console.log("connected, to receive");
      const data = JSON.parse(e);
      setChatMessages(data);
      //console.log('message received ', data);
    });


    axios.get(`${ApiConfig.baseUrl}messages`).then(res => {
      setChatMessages(res.data);
    });


    return () => {
      socket.off('receive_message');
    };
  }, []);

  const handleUserID = (event) => {
    setUserID(event.target.value);
  }

  const handleFilter = () => {
    if (!chatMessages) return [];
    if (!userID) return chatMessages;
    return chatMessages.filter(message => message.to === userID);
  };

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}

  return (
    <div>
      <h2>Inbox Page</h2>
      <input type="text" placeholder="Enter userID" onChange={handleUserID} />
      <button onClick={handleFilter}>Filter</button>
      {handleFilter().length > 0 ? (
        <ul>
          {handleFilter().map((m, index) => (
            <li key={index}>{m.message}</li>
          ))}
        </ul>
      ) : (
        <p>No messages found</p>
      )}
    </div>
  );
};

export default InboxPage;
