import React, { useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './../../App.css';
import logo from './../../images/logo.png';

const SocietyHeader = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [showFlatsMenu, setShowFlatsMenu] = useState(false);
  const [showParkingMenu, setShowParkingMenu] = useState(false);

  const handleLogout = () => {
    logout();
    localStorage.removeItem('isLoggedIn');
    navigate('/');
  };

  const toggleMenu = () => setShowMenu(!showMenu);
  const toggleFlatsMenu = () => setShowFlatsMenu(!showFlatsMenu);
  const toggleParkingMenu = () => setShowParkingMenu(!showParkingMenu);

  return (
    <div className='society-header'>
      <div className='logo-container'>
        <img src={logo} alt="Logo" />
        <div className='company-name'>Society Parking Management</div>
      </div>
      <div className='menu-icon' onClick={toggleMenu}>
        ☰
      </div>
      <nav className={`menu ${showMenu ? 'show' : ''}`}>
        <NavLink activeClassName="active" to="/upload-data">Upload Data</NavLink>
        <NavLink activeClassName="active" to="/user-management">User Management</NavLink>
        <div className='dropdown'>
          <span onClick={toggleFlatsMenu}>Flats</span>
          <div className={`dropdown-content ${showFlatsMenu ? 'show' : ''}`}>
            <NavLink activeClassName="active" to="/add-flat">Add Flat</NavLink>
            <NavLink activeClassName="active" to="/view-flats">View Flats</NavLink>
          </div>
        </div>
        <div className='dropdown'>
          <span onClick={toggleParkingMenu}>Parking</span>
          <div className={`dropdown-content ${showParkingMenu ? 'show' : ''}`}>
            <NavLink activeClassName="active" to="/parkingSlotMaster">Parking Slots</NavLink>
            <NavLink activeClassName="active" to="/parking-details">Link Slots</NavLink>
          </div>
        </div>
        <button onClick={handleLogout}>Logout</button>
      </nav>
    </div>
  );
}

export default SocietyHeader;