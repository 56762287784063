import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import io from "socket.io-client";
import ApiConfig from '../ApiConfig';


const isLogged = Boolean(localStorage.getItem("isLoggedIn"))



const socket = io(`${ApiConfig.socketUrl1}`, { transports: ['websocket'] });

const TestChat = () => {
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {message};
    socket.emit("send_message", JSON.stringify(data));
    setMessage("");
  };

  useEffect(() => {
    socket.on("receive_message", (e) => {
      const data = JSON.parse(e);
      setChatMessages(prevMessages => [...prevMessages, data]);
    });

    return () => {
      socket.off("receive_message");
    };
  }, []);

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}

  return (
    <div>
      <h2>Test Chat</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" value={message} onChange={handleChange} />
        <button type="submit">Send</button>
      </form>
      {chatMessages.map((m, index) => (
        <p key={index}>{m.message}</p>
      ))}
    </div>
  );
};

export default TestChat;
