import {
  Button,
  Checkbox,
  FormControl, FormControlLabel, InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import ApiConfig from '../ApiConfig';
import '../publicParking/LevelForm.css';
import SocietyHeader from './SocietyHeader';

const ParkingRegistrationForm = () => {
  const [blocks, setBlocks] = useState([]);
  const [towers, setTowers] = useState([]);
  const [flats, setFlats] = useState([]);
  const [slots, setSlots] = useState([]);
  const [blockName, setBlockName] = useState('');
  const [towerName, setTowerName] = useState('');
  const [flatNumber, setFlatNumber] = useState('');
  const [slotNumber, setSlotNumber] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [tagId, setTagId] = useState('');
  const [tagEpc, setTagEpc] = useState('');
  const [status, setStatus] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const [selectedBlock, setSelectedBlock] = useState('');
  const [selectedTower, setSelectedTower] = useState('');
  const [selectedFlat, setSelectedFlat] = useState('');
  const [flatId, setFlatId] = useState('');
  const [slotId, setSlotId] = useState('');
  const [parkingSlots, setParkingSlots] = useState([]);

  const isLogged = Boolean(localStorage.getItem("isLoggedIn"))

  useEffect(() => {
    fetchBlocks();
  }, []);
  const fetchBlocks = async () => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/blocks`);
      const data = await response.json();
      setBlocks(data);
    } catch (error) {
      console.error('Error fetching blocks:', error);
    }
  };
  const fetchTowers = async (blockName) => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/towers/${blockName}`);
      const data = await response.json();
      setTowers(data);
    } catch (error) {
      console.error('Error fetching towers:', error);
    }
  };
  const fetchFlats = async (blockName, towerName) => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/allottedFlats/${blockName}/${towerName}`);
      const data = await response.json();
      setFlats(data);
    } catch (error) {
      console.error('Error fetching flats:', error);
    }
  };
  const fetchAllottedSlots = async (flatId) => {
    try {
      const response = await fetch(`${ApiConfig.baseUrl}api/societyManagement/slots/${flatId}`);
      const data = await response.json();
      // setParkingSlots(data);
      setParkingSlots(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching available slots:', error);
    }
  };
  useEffect(() => {
    if (flatId) {
      // Fetch parking slots based on selected flat number
      axios.get(`${ApiConfig.baseUrl}api/societyManagement/slots/${flatId}`)
        .then(response => setSlots(response.data))
        .catch(error => console.error('Error fetching parking slots:', error));
    }
  }, [flatId]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submit Data", slotId, slotNumber, vehicleType, vehicleNumber, towerName, flatId, flatNumber, tagId, tagEpc, status)
    if (!slotNumber || !vehicleNumber || !towerName || !flatNumber || !vehicleType) {
      setHasError(true);
    } else {
      try {
        await axios.post(`${ApiConfig.baseUrl}parkingAllotmentSave`, {
          slotId, slotNumber, vehicleType, vehicleNumber, towerName, flatId, flatNumber, tagId, tagEpc, status: status ? 1 : 0
        });
        navigate("/parking-details");
      } catch (error) {
        console.error('Error saving parking allotment:', error);
        setErrorMsg('Error saving parking allotment. Please try again.');
      }
    }
  };
  const handleBlockChange = (event) => {
    const blockName = event.target.value;
    setSelectedBlock(blockName);
    fetchTowers(blockName);
    setSelectedTower('');
    setSelectedFlat('');
    setSlots([]);
  };
  const handleTowerChange = (event) => {
    const towerName = event.target.value;
    setSelectedTower(towerName);
    setTowerName(towerName)
    fetchFlats(selectedBlock, towerName);
    setSelectedFlat('');
    setSlots([]);
  };
  const handleFlatChange = (event) => {
    const selectedFlatId = event.target.value;
    const selectedFlat = flats.find(flat => flat.id === selectedFlatId);
    setFlatId(selectedFlat.id);
    setFlatNumber(selectedFlat.flat_number);
    fetchAllottedSlots(selectedFlatId)
  };
  const handleSlotChange = (event) => {
    const selectedSlot = slots.find(slot => slot.id === event.target.value);
    setSlotId(selectedSlot.id);
    setSlotNumber(selectedSlot.slot_info);
  };
  const renderErrorMessage = () => hasError && <div className="error">Please fill out all required fields (*)</div>;

  if (!isLogged) {
    return <Navigate to={"/"} replace={true} />
}

  return (
    <div>
      <SocietyHeader />
      <Box sx={{ p: 1, border: '2px solid grey', backgroundColor: '#c4c4c44d' , height: '100vh', overflow: 'auto' }}>
        <form onSubmit={handleSubmit}>
          <div className="SlotMas_form">
            <div className="form_head">Parking Registration Form</div>
            {renderErrorMessage()}
            {errorMsg && <div className="error">{errorMsg}</div>}
            <div className="form_body">
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel>Block</InputLabel>
                <Select value={selectedBlock} onChange={handleBlockChange}>
                  {blocks.map((block) => (
                    <MenuItem key={block.block_name} value={block.block_name}>
                      {block.block_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel>Tower</InputLabel>
                <Select value={selectedTower} onChange={handleTowerChange}>
                  {towers.map((tower) => (
                    <MenuItem key={tower.tower_name} value={tower.tower_name}>
                      {tower.tower_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel>Flats</InputLabel>
                <Select value={flatId} onChange={handleFlatChange}>
                  {flats.length > 0 ? (
                    flats.map((flat) => (
                      <MenuItem key={flat.id} value={flat.id}>
                        {flat.flat_number}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No flats available</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel>Parking Slot</InputLabel>
                <Select value={slotId} onChange={handleSlotChange}>
                  <MenuItem value="">Select Slot</MenuItem>
                  {slots.length > 0 ? (
                    slots.map(slot => (
                      <MenuItem key={slot.id} value={slot.id}>
                        {slot.slot_info}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Slot Not Allotted</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel>Vehicle Type</InputLabel>
                <Select value={vehicleType} onChange={(e) => setVehicleType(e.target.value)} required>
                  <MenuItem value="">Select Vehicle Type</MenuItem>
                  <MenuItem value="2 Wheeler">2 Wheeler</MenuItem>
                  <MenuItem value="4 Wheeler">4 Wheeler</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <TextField
                  label="Vehicle Number"
                  value={vehicleNumber}
                  onChange={(e) => setVehicleNumber(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <TextField
                  label="Tag ID"
                  value={tagId}
                  onChange={(e) => setTagId(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <TextField
                  label="Tag EPC"
                  value={tagEpc}
                  onChange={(e) => setTagEpc(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                    />
                  }
                  label="Status"
                />
              </FormControl>
            </div>
            <div className="form_footer">
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Link to="/parking-details">
                <Button type="button" variant="contained" color="secondary" style={{ marginLeft: '10px' }}>
                  Cancel
                </Button>
              </Link>
            </div>
          </div>
        </form>
      </Box>
    </div>
  );
};
export default ParkingRegistrationForm;