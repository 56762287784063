// src/ApiConfig.js

// const ApiConfig = {
//     baseUrl: 'http://localhost:2005/',
//     socketUrl1: 'http://localhost:3005',
//     socketUrl2: 'http://localhost:3006',
//   };
  
  const ApiConfig = {
    baseUrl: 'http://93.127.198.13:2005/',
    socketUrl1: 'http://93.127.198.13:3005',
    socketUrl2: 'http://93.127.198.13:3006',
  };

  export default ApiConfig;
  